<!-- Navbar -->
<HomeNavBar />
<section>
  <div class="row content-section">
    <div class="col-md-3">
      <AllGroups />
    </div>
    <div class="col-lg-6 col-12 mt-4">
      <div class="background-section p-3">
        <h5 class="text-white mx-3">{{$route.params.group_name}} activities</h5>
        <div class="card rounded-5 border-0 shadow-sm p-1 mt-4 m-3">
          <div class="d-block d-md-none d-flex justify-content-center">
            <ul class="pills-billing-tab nav nav-pills mb-3 mt-2" id="pills-billing-tab" role="tablist">
              <li class="nav-item text-xs me-3" role="presentation">
                <button class="nav-link active" id="normal_loans_tab" data-bs-toggle="pill"
                  data-bs-target="#normal_loans_info" type="button" role="tab" aria-controls="normal_loans_info"
                  aria-selected="true">
                  <i class='bx bxs-report fs-6'></i>
                  <span class="mx-2">Your Feed</span>
                </button>
              </li>
              <li class="nav-item text-xs" role="presentation">
                <button class="nav-link" id="top_loans_tab" data-bs-toggle="pill" data-bs-target="#top_loans_info"
                  type="button" role="tab" aria-controls="top_loans_info" aria-selected="false">
                  <i class='bx bxs-message-dots fs-5'></i>
                  <span class="mx-2">Your Voice</span>
                </button>
              </li>
            </ul>
          </div>

          <div class="d-none d-md-flex justify-content-center">
            <ul class="pills-billing-tab nav nav-pills mb-3" id="pills-billib-tab" role="tablist">
              <li class="nav-item text-sm me-3" role="presentation">
                <button class="nav-link active text-xs fw-500" id="normal_loans_tab" data-bs-toggle="pill"
                  data-bs-target="#normal_loans_info" type="button" role="tab" aria-controls="normal_loans_info"
                  aria-selected="true">
                  <a class="rounded-circle bg-yellow p-1 shadow-sm color-purple"><i class='bx bxs-report fs-5'></i></a>
                  <span class="mx-2">Your Feeds</span>
                </button>
              </li>
              <li class="nav-item text-sm mx-5" role="presentation">
                <button class="nav-link text-xs fw-500" id="top_loans_tab" data-bs-toggle="pill"
                  data-bs-target="#top_loans_info" type="button" role="tab" aria-controls="top_loans_info"
                  aria-selected="false">
                  <a class="rounded-circle bg-yellow p-1 shadow-sm color-purple"><i
                      class='bx bxs-message-dots fs-5'></i></a>
                  <span class="mx-2">Your Voice</span>
                </button>
              </li>

            </ul>
          </div>
        </div>
      </div>
      <div class="post-section mt-3 overflow-auto p-3 h-700 position-relative">
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="normal_loans_info" role="tabpanel" aria-labelledby="normal_loans_tab">
            <!--  -->
            <div v-if="group_feeds.length > 0">
              <div v-for="feed in group_feeds" :key="feed.id" class="card shadow card-hover bg-white border-0 mb-3 p-3 rounded-5 d-flex flex-column">
                  <div class="row flex-grow-1">
                      <div class="col">
                          <div class="d-flex flex-start">
                              <i class='bx bxs-user-circle text-secondary fs-2'></i>
                              <div class="flex-grow-1 flex-shrink-1">
                                  <div>
                                      <div class="d-flex justify-content-between align-items-center ms-2">
                                          <p class="fw-500 mt-1 lh-1 text-xs">
                                              {{ feed.creater.first_name }} {{ feed.creater.last_name }}
                                             <br> <span v-if="feed.date_created" class="text-xxs">
                                                  <i class="bx bxs-stopwatch"></i> {{ feed.date_created }}
                                              </span>
                                          </p>
                                          <div class="dropstart" v-if="String(feed.creater.id) === String(user_id) && token_exists">
                                              <a href="" class="fw-bold text-dark" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                  <i class="bi bi-three-dots-vertical"></i><span class="small"></span>
                                              </a>
                                              <ul class="dropdown-menu custom-dropdown" aria-labelledby="dropdownMenuButton">
                                                <li>
                                                  <a class="dropdown-item text-xs" href="" @click.prevent="deleteGroupFeed(feed.id)" >Delete</a>
                                                </li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
          
                          <p class="fw-500 text-s">{{ feed.name }}</p>
                          
                          <div class="text-xs display-6">
                            <span v-html="getDisplayedFeedDescription(feed)"></span>
                            <span 
                                v-if="shouldShowReadMore(feed)" 
                                class="text-primary cursor-pointer text-xxs" 
                                @click="toggleFeedReadMore(feed.id)"
                              >
                                <!-- Check if it should show 'Read More' and render the icon accordingly -->
                                {{ isFeedExpanded(feed.id) ? 'Read Less' : 'Read More' }}
                                <i v-if="!isFeedExpanded(feed.id)" class='bx bx-down-arrow-circle'></i>
                                <i v-else class='bx bx-up-arrow-circle' ></i>
                              </span>
                          </div>
                          <div class="d-flex flex-column" v-if="feed.feed_files && feed.feed_files.length">
                              <div v-for="(fileObj, index) in feed.feed_files" :key="index" class="mb-3">
                                  <!-- Image -->
                                  <img v-if="fileObj.file.file_url.includes('.jpg') || 
                                             fileObj.file.file_url.includes('.png') || 
                                             fileObj.file.file_url.includes('.jpeg')"
                                       :src="fileObj.file.file_url"
                                       alt="feed Image"
                                       class="img-fluid rounded-5 w-100 h-50"
                                       @click="display_comment_modal(feed)"/>

                                  <audio v-else-if="fileObj.file.file_url.includes('.mp3') || fileObj.file.file_url.includes('.wav')" controls class="w-100">
                                      <source :src="fileObj.file.file_url" type="audio/mp3">
                                      Your browser does not support the audio element.
                                  </audio>
                                  <video v-else controls class="img-fluid rounded-5 w-100">
                                      <source :src="fileObj.file.file_url" type="video/mp4">
                                      Your browser does not support the video tag.
                                  </video>
                                  
                              </div>
                          </div>
          
                          <!-- Like and comment count -->
                          <div class="mx-2 mt-1 text-end">
                              <span class="me-2 mt-2">{{ feed.total_likes|| 0 }} <i class='bx bxs-like'></i></span>
                              <span>{{feed.total_comments}}<i class='bx bxs-comment'></i></span>
                          </div>
          
                          <!-- Like and comment buttons -->
                          <div class="d-flex justify-content-between align-items-center border-top">
                              <div class="mb-3 ms-2 me-3 mt-1">
                                  <span class="d-flex text-secondary" :id="'like-button' + feed.id" @click="likeFeed(feed)">
                                      <i class="bi bi-hand-thumbs-up fw-bold"></i><span class="text-secondary text-xs mt-1 mx-1">Like</span>
                                  </span>
                                  <span :id="'liked-button' + feed.id" class="d-none d-flex text-primary" @click="unlikeFeed(feed)">
                                      <i class="bi bi-hand-thumbs-up-fill"></i><span class="text-primary text-xs mt-1 mx-1">Unlike</span>
                                  </span>
                              </div>
          
                              <!-- Comment Input -->
                              <div class="input-group mb-3 me-2 rounded-5 w-100 position-relative mt-1" @click="display_comment_modal(feed)">
                                  <input type="text" class="form-control text-xs rounded-start rounded-end" placeholder="Write a comment .." readonly>
                              </div>
                              
                                  <div class="mb-3 me-2 menu mt-1" @click.stop>
                                    <input type="checkbox" :id="'menu-open-' + feed.id" class="menu-open" name="menu-open" />
                                    <span><label :for="'menu-open-' + feed.id" class="menu-open-button text-xs text-secondary d-flex" @click="boon"><i class="bi bi-share"></i>
                                      <span class="text-secondary mx-1 ">Share</span></label></span>

                                      <a @click="copyFeedLinkToClipboard(feed)"  class="menu-item ">
                                        <span @click="closeFeedMenu(feed.id)"><i class="bi bi-link fs-4 copy text-primary text-xxs"></i></span>
                                      </a>
                                      <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(baseUrl(feed,'feed'))" target="_blank" class="menu-item facebook-share-btn" @click="closeFeedMenu(Feed.id)">
                                        <i class="bi bi-facebook"></i>
                                      </a>
                                      <a :href="'https://twitter.com/intent/tweet?url=' + encodeURIComponent(baseUrl(feed,'feed'))" target="_blank" class="menu-item twitter-share-btn" @click="closeFeedMenu(Feed.id)">
                                        <i class="bi bi-twitter-x"></i>
                                      </a>
                                      <a :href="'mailto:?subject=Check%20this%20out&body=' + encodeURIComponent(baseUrl(feed,'feed'))" target="_blank" class="menu-item gmail-share-btn" @click="closeFeedMenu(Feed.id)">
                                        <i class="bi bi-google"></i>
                                      </a>
                                      <a :href="'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(baseUrl(feed,'feed'))" target="_blank" class="menu-item linkedin-share-btn" @click="closeVFeedMenu(Feed.id)">
                                        <i class="bi bi-linkedin"></i>
                                      </a>
                                      <a :href="'https://api.whatsapp.com/send?text=' + encodeURIComponent(baseUrl(feed,'feed'))" target="_blank" class="menu-item whatsapp-share-btn" @click="closeFeedMenu(Feed.id)">
                                        <i class="bi bi-whatsapp"></i>
                                      </a>
                                      <a :href="'https://t.me/share/url?url=' + encodeURIComponent(baseUrl(feed,'feed'))" target="_blank" class="menu-item telegram-share-btn" @click="closeFeedMenu(Feed.id)">
                                        <i class="bi bi-telegram"></i>
                                      </a>

                                      <div aria-live="polite" aria-atomic="true" class="position-fixed custom-toast">
                                        <div id="copyToast" class="toast align-items-center text-bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
                                          <div class="d-flex">
                                            <div class="toast-body">
                                              Link copied to clipboard!
                                            </div>
                                            <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                                          </div>
                                        </div>
                                      </div>
    

                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          
          <div v-else class="text-center">No available feeds</div>
            <!--  -->

          </div>
          <div class="tab-pane fade" id="top_loans_info" role="tabpanel" aria-labelledby="top_loans_tab">
            <div v-if="group_voices.length">
              <div v-for="(voice, index) in group_voices" :key="voice.id" class="card shadow card-hover bg-white border-0 mb-3 p-3 rounded-5 d-flex flex-column">
                  <div class="row flex-grow-1">
                      <div class="col">
                          <div class="d-flex flex-start">
                              <i class='bx bxs-user-circle text-secondary fs-2'></i>
                              <div class="flex-grow-1 flex-shrink-1">
                                  <div>
                                      <div class="d-flex justify-content-between align-items-center ms-2">
                                          <p class="fw-500 mt-1 lh-1 text-xs">
                                              {{ voice.creater.first_name }} {{ voice.creater.last_name }}
                                             <br> <span v-if="voice.date_created" class="text-xxs">
                                                  <i class="bx bxs-stopwatch"></i> {{ voice.date_created }}
                                              </span>
                                          </p>
                                          <div class="dropstart" v-if="String(voice.creater.id) === String(user_id) && token_exists">
                                              <a href="" class="fw-bold text-dark" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                  <i class="bi bi-three-dots-vertical"></i><span class="small"></span>
                                              </a>
                                              <ul class="dropdown-menu custom-dropdown" aria-labelledby="dropdownMenuButton">
                                                <li>
                                                  <a class="dropdown-item text-xxs" href="" @click.prevent="deleteGroupVoice(voice.id)" >Delete</a>
                                                </li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
          
                          <p class="fw-500 text-s">{{ voice.name }}</p>
                          
                          <div class="text-xs display-6">
                            <span v-html="getDisplayedVoiceDescription(voice)"></span>
                            <span 
                                v-if="shouldShowReadMore(voice)" 
                                class="text-primary cursor-pointer text-xxs" 
                                @click="toggleVoiceReadMore(voice.id)"
                              >
                                <!-- Check if it should show 'Read More' and render the icon accordingly -->
                                {{ isVoiceExpanded(voice.id) ? 'Read Less' : 'Read More' }}
                                <i v-if="!isVoiceExpanded(voice.id)" class='bx bx-down-arrow-circle'></i>
                                <i v-else class='bx bx-up-arrow-circle' ></i>
                              </span>                          
                            </div>
                          <div class="d-flex flex-column" v-if="voice.voice_files && voice.voice_files.length">
                              <div v-for="(fileObj, index) in voice.voice_files" :key="index" class="mb-3">
                                  <!-- Image -->
                                  <img v-if="fileObj.file.file_url.includes('.jpg') || 
                                             fileObj.file.file_url.includes('.png') || 
                                             fileObj.file.file_url.includes('.jpeg')"
                                       :src="fileObj.file.file_url"
                                       alt="voice Image"
                                       class="img-fluid rounded-5 w-100 h-50"
                                       @click="display_voice_comment(voice)"/>


                                  <audio v-else-if="fileObj.file.file_url.includes('.mp3') || fileObj.file.file_url.includes('.wav')" controls class="w-100">
                                      <source :src="fileObj.file.file_url" type="audio/mp3">
                                      Your browser does not support the audio element.
                                  </audio>
                                  <video v-else controls class="img-fluid rounded-5 w-100">
                                      <source :src="fileObj.file.file_url" type="video/mp4">
                                      Your browser does not support the video tag.
                                  </video>
                                  
                              </div>
                          </div>
          
                          <!-- Like and comment count -->
                          <div class="mx-2 mt-1 text-end">
                              <span class="me-2 mt-2">{{ voice.total_likes||0 }} <i class='bx bxs-like'></i></span>
                              <span>{{voice.total_comments}}<i class='bx bxs-comment'></i></span>
                          </div>
          
                          <!-- Like and comment buttons -->
                          <div class="d-flex justify-content-between align-items-center border-top">
                              <div class="mb-3 ms-2 me-3 mt-1">
                                  <span class="d-flex text-secondary" :id="'react-button' + voice.id" @click="likeVoice(voice)">
                                      <i class="bi bi-hand-thumbs-up fw-bold"></i><span class="text-secondary text-xs mt-1 mx-1">Like</span>
                                  </span>
                                  <span :id="'reacted-button' + voice.id" class="d-none d-flex text-primary" @click="unlikeVoice(voice)">
                                      <i class="bi bi-hand-thumbs-up-fill"></i><span class="text-primary text-xs mt-1 mx-1">Unlike</span>
                                  </span>
                              </div>
          
                              <!-- Comment Input -->
                              <div class="input-group mb-3 me-2 rounded-5 w-100 position-relative mt-1" @click="display_voice_comment(voice)">
                                  <input type="text" class="form-control text-xs rounded-start rounded-end" placeholder="Write a comment .." readonly/>
                              </div>
                              <div class="mb-3 me-2 menu mt-1" @click.stop>
                                <input type="checkbox" :id="'menu-open-' + voice.id" class="menu-open" name="menu-open" />
                                <span><label :for="'menu-open-' + voice.id" class="menu-open-button text-xs text-secondary d-flex" @click="boon"><i class="bi bi-share"></i>
                                  <span class="text-secondary mx-1 ">Share</span></label></span>
                                  <a @click="copyVoiceLinkToClipboard(voice)"  class="menu-item ">
                                    <span @click="closeFeedMenu(voice.id)"><i class="bi bi-link fs-4 copy text-primary text-xxs"></i></span>
                                    </a>
                                <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(baseUrl(voice,'voice'))" target="_blank" class="menu-item facebook-share-btn" @click="closeFeedMenu(voice.id)">
                                  <i class="bi bi-facebook"></i>
                                </a>
                                <a :href="'https://twitter.com/intent/tweet?url=' + encodeURIComponent(baseUrl(voice,'voice'))" target="_blank" class="menu-item twitter-share-btn" @click="closeFeedMenu(voice.id)">
                                  <i class="bi bi-twitter-x"></i>
                                </a>
                                <a :href="'mailto:?subject=Check%20this%20out&body=' + encodeURIComponent(baseUrl(voice,'voice'))" target="_blank" class="menu-item gmail-share-btn" @click="closeFeedMenu(voice.id)">
                                  <i class="bi bi-google"></i>
                                </a>
                                <a :href="'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(baseUrl(voice,'voice'))" target="_blank" class="menu-item linkedin-share-btn" @click="closeFeedMenu(voice.id)">
                                  <i class="bi bi-linkedin"></i>
                                </a>
                                <a :href="'https://api.whatsapp.com/send?text=' + encodeURIComponent(baseUrl(voice,'voice'))" target="_blank" class="menu-item whatsapp-share-btn" @click="closeFeedMenu(voice.id)">
                                  <i class="bi bi-whatsapp"></i>
                                </a>
                                <a :href="'https://t.me/share/url?url=' + encodeURIComponent(baseUrl(voice,'voice'))" target="_blank" class="menu-item telegram-share-btn" @click="closeFeedMenu(report.id)">
                                  <i class="bi bi-telegram"></i>
                                </a>
        
                                <div aria-live="polite" aria-atomic="true" class="position-fixed custom-toast">
                                  <div id="copyTheToast" class="toast align-items-center text-bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
                                    <div class="d-flex">
                                      <div class="toast-body">
                                      </div>
                                      <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                      </div>
                  </div>
              </div>
          </div>
          
          <div v-else class="text-center">No available voices</div>
          
        </div>

        </div>
      </div>
    </div>

    <div class="col-lg-3 d-none d-xl-block mt-4">
      <div class="mx-5 rounded-5 pb-1 position-relative top-54px">
        <h6 class='text-start mx-1 d-flex justify-content-between align-items-center'>Group members
          <div v-if="!deleteMode" class="dropstart">
            <a href="" class="fw-bold text-dark" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bi bi-three-dots-vertical"></i><span class="small"></span>
            </a>
            <ul class="dropdown-menu custom-dropdown" aria-labelledby="dropdownMenuButton">
              <li v-if="isCreator">
                <a class="dropdown-item text-xs border-bottom" @click="showDeleteMode">
                  <i class='bx bxs-trash-alt fs-6'></i>  Delete Member(s)
                </a>
              </li>
              <li>
                <a class="dropdown-item text-xs" @click="showExitMode">
                  <i class="bi bi-door-open"></i>  Exit Group
                </a>
              </li>
            </ul>
          </div>
          <i class="bi bi-trash text-danger fs-4" @click="removeSelectedMembers" v-if="isCreator && selectedMembers.length"></i>
          </h6>
        <hr />
        <!-- Create Group Modal -->
        <div class="table-container ">
          <table v-if="group_members.length" class="table mb-0">
            <tbody>
              <tr v-for="member in group_members" :key="member.id" class="hover-row border-0">
                <td class="border-0">
                  <span class="text-decoration-none text-dark">
                    <p class="portal-icon mb-0 d-flex align-items-center">
                      <input type="checkbox" v-if="deleteMode && member.user.id != user_id" v-model="selectedMembers"
                        :value="member.user.id" class="me-2" @change="handleFileUpload"/>
                      <a class="rounded-circle bg-yellow p-1 shadow-sm color-purple">
                        <i class='bx bx-user fs-5'></i>
                      </a>
                      <span class="text-xs fw-500 mx-2 color-blue">{{ member.user.first_name }}{{" "}}{{
                        member.user.last_name }}</span>
                    </p>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <p v-else class="text-secondary text-xs text-center">
            No Group members Available
          </p>
        </div>
      </div>
    </div>
  </div>
  <FloatingButton v-if="token_exists" :group_id="$route.params.group_id" :created_by="$route.params.created_by" />
  <StickyNav/>

  <div class="offcanvas offcanvas-start" tabindex="-1" id="group_members" aria-labelledby="group_members_label">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="groups_label"></h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body"> 
        <h6 class='text-start mx-1 d-flex justify-content-between align-items-center'>Group members
          <div v-if="!deleteMode" class="dropstart">
            <a href="" class="fw-bold text-dark" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bi bi-three-dots-vertical"></i><span class="small"></span>
            </a>
            <ul class="dropdown-menu custom-dropdown" aria-labelledby="dropdownMenuButton">
              <li v-if="isCreator">
                <a class="dropdown-item text-xs border-bottom" @click="showDeleteMode">
                  <i class='bx bxs-trash-alt fs-6'></i>  Delete Member(s)
                </a>
              </li>
              <li>
                <a class="dropdown-item text-xs" @click="showExitMode">
                  <i class="bi bi-door-open"></i>  Exit Group
                </a>
              </li>
            </ul>
          </div>
          <i class="bi bi-trash text-danger fs-4" @click="removeSelectedMembers" v-if="selectedMembers.length"></i>
          </h6>
        <hr />
        <!-- Create Group Modal -->
        <div class="table-container ">
          <table v-if="group_members.length" class="table mb-0">
            <tbody>
              <tr v-for="member in group_members" :key="member.id" class="hover-row border-0">
                <td class="border-0">
                  <span class="text-decoration-none text-dark">
                    <p class="portal-icon mb-0 d-flex align-items-center">
                      <input type="checkbox" v-if="deleteMode && member.user.id != user_id" v-model="selectedMembers"
                        :value="member.user.id" class="me-2" @change="handleFileUpload"/>
                      <a class="rounded-circle bg-yellow p-1 shadow-sm color-purple">
                        <i class='bx bx-user fs-5'></i>
                      </a>
                      <span class="text-xs fw-500 mx-2 color-blue">{{ member.user.first_name }}{{" "}}{{
                        member.user.last_name }}</span>
                    </p>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <p v-else class="text-secondary text-xs text-center">
            No Group members Available
          </p>
        </div>
    </div>
  </div>
</section>
<GroupFeedCommentModal v-if="comment_modal_visible"
  :feed_data="feed_data"
  @hide_comment_comment="hide_comment_comment"
/>

<GroupVoiceCommentModal v-if="voice_comment_modal_visible"
  :voice_data="voice_data"
  @hide_voice_comment_comment="hide_voice_comment_comment"
/>