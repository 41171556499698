<div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="comment_label"
    aria-hidden="true"
    id="comment_modal"
>
    <div class="modal-fullscreen modal-dialog modal-dialog-centered">
        <div class="modal-content">
 
            <div class="modal-header">
                <h6 class="modal-title fw-bold text-center" id="comment_label">
                {{ exchange_hubs.creater.first_name }}'s Voice
                </h6>
                <button
                    type="button"
                    class="btn-close"
                    @click="hide_modal"
                ></button>
            </div>
 
            <div class="modal-body">
                <div class="card card-hover bg-transparent border-0 mb-3">
                    <div class="row g-0">
 
                        <div class="col-md-7">
 
                            <div class="row">
                                <div class="col-md-7">
                                <h6 class="text-xs fw-bold">{{ exchange_hubs.name }}</h6>
                                </div>
                                <div class="col-md-5 text-end">
                                    <span class="text-xxs"> {{ exchange_hubs.publication_date }}</span>
                                </div>
 
                            </div>
 
                            <a
                                v-if="exchange_hubs.exchange_hubs_files "
                                :href="exchange_hubs.exchange_hubs_link"
                                class="text-xxs d-block mb-3"
                            >
                                <span class="text-dark fw-bold"><i class="bx bx-link"></i></span>
                                {{ exchange_hubs.exchange_hubs_link }}
                            </a>
                            <p class="text-xs mt-3">
                                <span class="text-secondary text-xs flex-grow-1" v-if="exchange_hubs.description.length <= 250">
                                  <span v-html="exchange_hubs.description"></span>
                                </span>
                                <span v-else>
                                  <span v-if="expandedHub.includes(exchange_hubs.id)">
                                    <span v-html="exchange_hubs.description"></span>
                                    <span class="text-xs mt-3 text-warning" @click="readMore(exchange_hubs.id)" >
                                      Read less
                                    </span>
                                  </span>
                                  <span v-else>
                                    <span v-html="exchange_hubs.description.substring(0, 250)"></span>...
                                    <span class="text-xs mt-3 text-warning" @click="readMore(exchange_hubs.id)">
                                      Read more
                                    </span>
                                  </span>
                                </span>
                              </p>
                             
                            <div class="image-container position-relative">
                                <div class="d-flex flex-column" v-if="exchange_hubs.document && exchange_hubs.document.length">
                                    <div v-for="(fileObj, index) in exchange_hubs.document" :key="index" class="mb-3">
                                      <!-- Image -->
                                      <img v-if="fileObj.file.file_url.includes('.jpg') ||
                                        fileObj.file.file_url.includes('.png') ||
                                        fileObj.file.file_url.includes('.jpeg')"
                                           :src="fileObj.file.file_url"
                                           alt="Feed Image"
                                           class="img-fluid rounded-5 w-100 h-50"
                                           @click="display_comment_modal(report)"/>
                                     
                                      <!-- Audio -->
                                      <audio v-else-if="fileObj.file.file_url.includes('.mp3') || fileObj.file.file_url.includes('.wav')"
                                             controls
                                             class="w-100">
                                        <source :src="fileObj.file.file_url" type="audio/mp3">
                                        Your browser does not support the audio element.
                                      </audio>
                                     
                                      <!-- Video -->
                                      <video v-else
                                             controls
                                             class="img-fluid rounded-5 w-100">
                                        <source :src="fileObj.file.file_url" type="video/mp4">
                                        Your browser does not support the video tag.
                                      </video>
                                    </div>
                                  </div>
                           
                                <!-- Zoom In/Out Button -->
                               
                              </div>
                         
                        </div>
 
                        <div class="col-md-5 comment-section ps-2" >
 
                            <div v-for="comment_data in comments_data" class="my-5">
 
                                <div class="d-flex">
 
                                    <i class="bi bi-person-circle fs-2 me-3"></i>
 
                                    <div class="flex-grow-1 border-custom">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <p class="mb-1 text-xs fw-bold">
                                                {{ comment_data.user.first_name }} {{ comment_data.user.last_name }}
                                                <span class="small">
                                                    {{ comment_data.time }}
                                                </span>
                                            </p>
 
                                            <div class="dropstart"  v-if="String(comment_data.user.id) === String(user_id)">
                                                <a href="" class="fw-bold text-dark" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                  <i class="bi bi-three-dots-vertical"></i><span class="small"></span>
                                                </a>
                                                <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
                                                    <li v-if="comment_data.total_replies === 0">
                                                        <a class="dropdown-item text-info" href="#!" @click="edit_exchangehub_comment(comment_data)">
                                                            <i class="bi bi-pencil"></i> Edit
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item text-danger" href="#!" @click="delete_exchangehub_comment(comment_data.id)">
                                                            <i class="bi bi-trash"></i> Delete
                                                        </a>
                                                    </li>
                                                    </ul>
                                            </div>
                                        </div>
                                        <p class="small mb-0 text-xs text-break">
                                            <span  class="text-secondary text-xs flex-grow-1" v-if="comment_data.comment_text.length <= 100">
                                                {{ comment_data.comment_text }}
                                              </span>
                                              <span v-else>
                                                <span v-if="expandedHubComments.includes(comment_data.id)">
                                                    {{ comment_data.comment_text }}
                                                  <p class="text-xs mt-3 text-warning cursor-pointer" @click="toggleReadMore(comment_data.id)">
                                                    Read less
                                                  </p>
                                                </span>
                                                <span v-else>
                                                  {{ comment_data.comment_text.substring(0, 100) }}...
                                                  <p class="text-xs mt-3 text-warning cursor-pointer" @click="toggleReadMore(comment_data.id)">
                                                    Read more
                                                  </p>
                                                </span>
                                              </span>
                                        </p>
                                        <div v-if="editing_comment_id === comment_data.id">
                                            <div class="input-group mb-3 rounded-5">
                                                <input type="text" class="form-control text-xs rounded-start" v-model="editing_comment_text" placeholder="Edit your comment"/>
                                                <span class="input-group-text bg-transparent p-2 bg-light rounded-end" @click="save_edit_comment(comment_data.id)">
                                                    <i class="bi bi-send"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
 
                                </div>
 
                                <div :id="'reply_toggler_section_'+comment_data.id">
                                    <div v-if="comment_data.total_replies != 0"
                                        class="float-start d-flex mx-5"
                                        @click="get_comment_replies(comment_data.id,1)"
                                    >
                                        <span class="text-xxs border-2 ms-2 mt-1 reply-toggler-button text-info">
                                            <i class="bi bi-eye"></i> View {{ comment_data.total_replies }} replies
                                        </span>
                                    </div>
                                    <div v-else class="float-start d-flex mx-5" @click="toggler_comment_input(comment_data.id,1)">
                                        <span class="text-xxs border-2 ms-2 mt-1 reply-toggler-button text-info">
                                            <i class="bi bi-reply"></i> reply
                                        </span>
                                    </div>
 
                                </div>
 
                                <div class="d-none ms-5 mt-3" :id="'reply_container_'+comment_data.id">
 
                                    <div :id="'reply_section_'+comment_data.id">
                                    </div>
 
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="input-group mb-3 rounded-5 w-100">
                                            <input
                                                :id="'new_reply_text'+comment_data.id"
                                                type="text"
                                                class="form-control text-xs rounded-start"
                                                placeholder="Write a reply..."
                                                @keypress.enter.prevent="submit_reply(comment_data.id)"
                                            />
                                            <span class="input-group-text bg-transparent p-2 bg-light rounded-end"
                                            @click="submit_reply(comment_data.id)">
                                            <i class="bi bi-send"></i>
                                        </span>
                                    </div>
                                    </div>
                                </div>
 
                            </div>
 
                        </div>
 
                    </div>
 
                </div>
 
            </div>
 
            <div class="modal-footer">
                <div class="mb-3">
                    <span>Comments ({{ exchange_hubs.total_comments }})</span>
                </div>
                <p class="fs-6 fw-bold fw-500 text-end">
                <span class="text-secondary text-xs fs-5 mb-3 ms-2 me-2 d-none exchange_hubs-card"
                    ><i class="bi bi-hand-thumbs-up"></i> Like</span
                >
                <span class="text-secondary text-xs fs-5 mb-3 ms-2 me-2 d-none exchange_hubs-card mx-5"
                    ><i class="bx bx-share "></i> Share</span
                >
                </p>
                <div class="input-group mb-3 rounded-5">
 
                    <input
                        id="comment_text_area"
                        type="text"
                        class="form-control text-xs"
                        placeholder="Write a comment .."
                        @keypress.enter.prevent="submit_comment()"
                    />
 
                    <span
                        class="input-group-text bg-transparent p-2 btn border-1 border-secondary"
                        @click="submit_comment()"
                    >
                        <i class="bi bi-send"></i>
                    </span>
 
                </div>
            </div>
 
        </div>
    </div>
</div>
 <!-- Reply Template -->
 <div class="d-none flex-grow-1 border-custom my-2 ms-3" id="reply_template">
    <div class="d-flex justify-content-between align-items-center">
        <p class="mb-1 text-xs fw-bold">
            <span class="me-1 reply_Name"></span>
            <span class="me-1 reply_last_name"></span>
            <span class="small reply_time"></span> 
        </p>
        <div class="dropstart reply_dropdown d-none">
            <a href="#" class="text-dark" data-bs-toggle="dropdown">
                <i class="bi bi-three-dots-vertical"></i>
            </a>
            <ul class="dropdown-menu w-100">
                <li><a class="dropdown-item text-info edit_reply"><i class="bi bi-pencil"></i> Edit</a></li>
                <li><a class="dropdown-item text-danger delete_reply"><i class="bi bi-trash"></i> Delete</a></li>
            </ul>
        </div>
    </div>
    <div class="reply_text_container">
        <p class="small mb-2 comment_text"></p>
        <div class="input-group mb-3 edit_reply_input d-none">
            <input type="text" class="form-control text-xs rounded-start edit_reply_text" placeholder="Edit your reply" />
            <span class="input-group-text save_edit_reply">
                <i class="bi bi-send"></i>
            </span>
        </div>
    </div>
</div>

<div class="read-more-section text-xs d-none" id="read_more_template">
    <span class="text-primary cursor-pointer read-more-button">Read more</span>
    <span class="text-primary cursor-pointer read-less-button d-none">Read less</span>
</div>
