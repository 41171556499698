import { get_token, get_user_details} from "@/router";

import Swal from 'sweetalert2';


export default {

    mounted() {
      this.path_route_name = this.$route.name;
      if(this.$route.name === 'my_community') {
       this.filterPortals();
      }
      else {
        this.fetchPortals();
      }

      this.user_details = get_user_details();
      this.token_exists = get_token();
      this.user_id = this.get_user_id();
    },

    data() {
      return ({
        portal_name: '',
        is_public: true,
        portals: [],
        errors: {},
        isLoading: false,
        token_exists: false,
        user_details: {
          "user_role": '',
          "user_id": null,
        },
        selectedPortal: null,
        requestedPortals: [],
        requested_users: [],
        path_route_name: '',
      });
    },
  watch:{
  '$route.params._id'() {
    this.get_portal_access_requested_users();
  }
  },
    methods:{
        getToken() {
            return get_token();
        },
        get_user_id() {
          const user_id = localStorage.getItem('user_id') || '';
          return user_id;
      },

        createPortal(){
          if (!this.validatePortalForm()) {
            return;
          }

          $.ajax({
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_portal_api/`,
            method: 'POST',
            headers: {
                'Authorization': `Token ${this.getToken()}`,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                portal_name: this.portal_name,
                is_public: this.is_public
            }),
            success: (data) => {
                if (data.status === "success") {
                  Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Portal created successfully.',
                    showConfirmButton: false,
                    timer: 1500
                  }).then(() => {
                    window.location.reload();
                    $('#create_portal').modal('hide');
                  })
                }
            },
            error: () => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to create portal.',
                    showConfirmButton: false,
                    timer: 1500
                  }).then(() => {
                    window.location.reload();
                    $('#create_portal').modal('hide');
                  })
            }

          })

        },
        validatePortalForm() {
          this.errors = {};
          let isValid = true;
          if (!this.portal_name.trim()) {
            this.errors.portal_name = 'Portal name is required.';
            isValid = false;
          }

          return isValid;
        },
        fetchPortals() {
            this.isLoading = true;
            $.ajax({
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_portals_api/`,
            method: 'GET',
            headers: {
                'Authorization': `Token ${this.getToken()}`,
                'Content-Type': 'application/json'
            },
            success: (data) => {
                this.portals = data.portals;

            },
            complete: () => {
                this.isLoading = false;
            }
            });
        },
        handlePortalClick(portal) {
          if (!this.handle_unauthenticated()) {
            return;
          }
          this.is_public = portal.is_public

          if (this.path_route_name === "my_community") {

            if (portal.has_access) {
              this.open_portal(portal.id, portal.name);
              $("#request_access").modal('hide');
            }

            else {
              $("#request_access").modal('show');
              this.selectedPortal = portal;
            }
            return;
          }

        if (portal.is_public) {
          this.open_portal(portal.id, portal.name);
          $("#request_access").modal('hide');
        }

        else if (String(portal.creater_id) === String(this.user_details.user_id)  && !portal.is_public) {
          this.open_portal(portal.id, portal.name);
this.get_portal_access_requested_users();
          $("#request_access").modal('hide');
          this.requested_users = []
        }

        else {
          this.requested_users = []
          const requestPortalId = this.requestedPortals.map(portal => portal.portal.id);
          if(requestPortalId.includes(portal.id)) {

            Swal.fire({
              icon: 'info',
              title: 'Info',
              text: 'Access request already submitted.',
              showConfirmButton: false,
              timer: 3000
            })
            return;
          }
          else{
            $("#request_access").modal('show');
            this.selectedPortal = portal;
          }
        }
        },
        getProfileLink(member) {
          // Check if the user is an admin
          if (member.role.role === 'admin') {
            // Return the link for admin profiles
            return `/profile?user_id=${member.id}`;
          } else if (member.role.role === 'customer') {
            // Return the link for customer profiles
            return `/customer_profile?user_id=${member.id}`;
          } else {
            // Return a default profile link for other roles
            return `/profile?user_id=${member.id}`;
          }
        },

        requestAccess() {
          this.isLoading = true;
          $.ajax({
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/request_access_private_portal/`,
            method: 'POST',
            headers: {
                'Authorization': `Token ${this.getToken()}`,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                portal_id: this.selectedPortal.id
            }),
            success: (data) => {
                if (data.status === "success") {
                  Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Access request submitted successfully.',
                    showConfirmButton: false,
                    timer: 3600
                  })
                }
            },
            error: (response) => {

                Swal.fire({
                    icon: 'info',
                    title: 'Request Already Sent',
                    text: response.responseJSON.message,
                    showConfirmButton: false,
                    timer: 3000
                  })
            },
            complete: () => {
                this.isLoading = false;
                this.selectedPortal = null;
                $("#request_access").modal('hide');
            }
          });
        },
        get_portal_access_requested_users() {
          this.isLoading = true;
          $.ajax({
          url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_portal_access_requested_users/`,
          method: 'GET',
          data: {portal_id: this.$route.params.portal_id},
          headers: {
              'Authorization': `Token ${this.getToken()}`,
              'Content-Type': 'application/json'
          },
          success: (response) => {
              this.requested_users = response.data;
          },
          complete: () => {
              this.isLoading = false;
          }
          });
      },


      approve_portal_access_request(user_id) {
        this.isLoading = true;
        $.ajax({
          url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/approve_portal_access_request/`,
          method: 'POST',
          headers: {
              'Authorization': `Token ${this.getToken()}`,
              'Content-Type': 'application/json'
          },
          data: JSON.stringify({
              user_id: user_id,
              portal_id: this.$route.params.portal_id
          }),
          success: (data) => {
              if (data.status === "success") {
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Access request approved successfully.',
                  showConfirmButton: false,
                  timer: 3600
                }).then(() => {
                  window.location.reload();
                });
              }
          },
          complete: () => {
              this.isLoading = false;
          }
        });
      },

      reject_portal_access_request(user_id) {
        this.isLoading = true;
        $.ajax({
          url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/approve_portal_access_request/`,
          method: 'POST',
          headers: {
              'Authorization': `Token ${this.getToken()}`,
              'Content-Type': 'application/json'
          },
          data: JSON.stringify({
              user_id: user_id,
              portal_id: this.$route.params.portal_id
          }),
          success: (data) => {
              if (data.status === "success") {
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Access request approved successfully.',
                  showConfirmButton: false,
                  timer: 3600
                }).then(() => {
                  window.location.reload();
                });
              }
          },
          complete: () => {
              this.isLoading = false;
          }
        });
      },
        handle_unauthenticated() {
            if (!this.token_exists) {
                this.$router.push({ name: 'sign_in_finale'});
              return false;
            }
            return true;
        },


        open_portal(portal_id, portal_name) {
          this.$router.push({ name: 'home_filter', params: {portal_id: portal_id, portal_name:portal_name}});
          this.filterReports(portal_id);
        },

        filterReports(portal_id) {
          this.isLoading = true;
          const payload = {
            filter_values: {
              topic__portal_id: portal_id
            }
          }
          $.ajax({
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/filter_reports_api/`,
            method: 'POST',
            headers: {
                'Authorization': `Token ${this.getToken()}`,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(payload),
            success: (response) => {
              if(response.status === "success") {
                this.$emit('portal_selected', response.data.report_data);
              }
            },
            complete: () => {
                this.isLoading = false;
            }
          });
        },

        filterPortals() {
          this.isLoading = true;
          $.ajax({
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/filter_portals_api/`,
            method: 'GET',
            headers: {
                'Authorization': `Token ${this.getToken()}`,
                'Content-Type': 'application/json'
            },
            success: (response) => {
              if(response.status === "success") {
                this.portals = response.portals;
              }
            },
            complete: () => {
                this.isLoading = false;
            }
          });
        },


    }
}