import { clear_token, get_token, get_user_details } from "@/router";
import HomeNavBar from "@/components/HomeNavBar/HomeNavBar.vue"
import ReportCommentModal from "@/components/ReportCommentModal/ReportCommentModal.vue";
import ExchangeHubCommentModal from "@/components/ExchangeHubCommentModal/ExchangeHubCommentModal.vue";
import AllPortals from "@/components/GetPortals/PortalsComponent.vue"
import AllGroups from "@/components/GetGroups/GroupsComponent.vue"
import FloatingButton from "@/components/FloatingButton/FloatingButtonComponent.vue"
import StickyNav from "@/components/BottomNavbar/StickyNavbar.vue"
import AllCommunityAssets from "@/components/GetCommunityAssets/CommunityAsset.vue"

import Swal from 'sweetalert2';

export default {

  components: {
    ReportCommentModal,
    ExchangeHubCommentModal,
    HomeNavBar,
    AllPortals,
    AllGroups,
    AllCommunityAssets,
    FloatingButton,
    StickyNav
  },
  mounted() {
    this.fetchExternalReports();
    this.fetchReports();
    this.fetchExchangeHubs();
    this.token_exists = get_token();
    this.user_id = this.get_user_id();
    this.getReportLikes();
    this.getExchangeHubLikes();
    document.addEventListener('click', this.handleClickOutside);
    this.user_details=get_user_details();
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  props: ['topic_id'],
  data() {
    return ({
      reports:[],
      topic_id:'',
      external_reports:[],
      exchange_hub_data:[],
      isLoading: false,
      token_exists: false,
      user_id:null,
      user_details: {
        "user_role": '',
        "first_name": '',
        "last_name": '',
      },
      report_data: {},
      exchange_hubs: {},
      comment_modal_visible: false,
      xHub_comment_modal_visible: false,
      expandedReports: [],
      expandedHub: [],
      reportLikes: {},
      likedExchangeHubs: []
    });
  },
  
  methods: {
    get_user_id() {
      const user_id = localStorage.getItem('user_id') || '';
      return user_id;
  },
  baseUrl(post, type) {
    const baseUrl = `${window.location.origin}${this.$route.path}`;
    // Dynamically build the URL based on the type (either 'hub' or 'report')
    if (type === 'exchange_hub') {
      return `${baseUrl}?hub_id=${post.id}`;
    } else if (type === 'report') {
      return `${baseUrl}?report_id=${post.id}`;
    }
    return baseUrl
  },
  getIdFromURL(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  },
  closeMenu(reportId) {
    const checkbox = document.getElementById('menu-open-' + reportId);
    if (checkbox) {
      checkbox.checked = false;
    }
  },
  
  closeFeedMenu( exchange_hubID) {
    const checkbox = document.getElementById('menu-open-' + exchange_hubID);
    if (checkbox) {
      checkbox.checked = false;
    }
  },

  fetchPostsByPortal(reports){
    this.reports = reports;
  },
  handleClickOutside(event) {
    const menus = document.querySelectorAll('.menu-open');
    menus.forEach(menu => {
      if (menu.checked && !menu.closest('.menu').contains(event.target)) {
        menu.checked = false;  
      }
    });
  },
    getToken() {
      return get_token();
    },
    deleteReport(report_id) {
      if (!this.token_exists) {
        this.$router.push('/sign_in_finale');
        return;
      }
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          $.ajax({
            type: "DELETE",
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/delete_report_api/`,
            data: JSON.stringify({ report_id: report_id }),
            headers: {
              'Authorization': `Token ${this.getToken()}`,
              'Content-Type': 'application/json'
            },
            success: (response) => {
              const status = response.status;
              if (status === "success") {
                Swal.fire({
                  title: 'Deleted!',
                  text: 'The feed has been deleted.',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1000
                }).then(() => {
                  window.location.reload();
                });
              } else {
                Swal.fire(
                  'Failed!',
                  response.message || 'An error occurred. Please try again.',
                  'error'
                );
              }
            },
            error: () => {
              Swal.fire(
                'Failed!',
                'An error occurred. Please try again.',
                'error'
              );
            }
          });
        }
      });
    },

    fetchExternalReports() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_external_reports_api`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        success: (response) => {
          var status = response.status
          if (status == "success") {
            this.external_report = response.reports
          }

        },

        complete: () => {
          this.isLoading = false;
        }
      });
    },
    fetchExchangeHubs() {
      
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_exchange_hubs_api/`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },

        success: (response) => {
          var status =response.status
          if(status== "success"){
            this.exchange_hub_data = response.exchange_hubs

          const hub_id = this.getIdFromURL('hub_id');
          if (hub_id) {
            const exchangeHub = this.exchange_hub_data.find(hub => hub.id === parseInt(hub_id,10));
            if (exchangeHub) {
              this.display_xHub_comment(exchangeHub);
            }
          }

            this.exchange_hub_data.forEach(exchangeHub => {
              if (exchangeHub && exchangeHub.id) {
                this.getExchangeHubLikes(exchangeHub);
              }
            });
          }
        },
        complete: () => {
          this.isLoading = false;
        },
        
      });
      
    },

    fetchReports() {
      this.isLoading = true;
      const topicId = this.topic_id;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_report_api/`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: { topic_id: topicId },
        success: (data) => {
          var status =data.status
          if(status === "success"){
            this.reports = data.report_data

            const reportId = this.getIdFromURL('report_id');
            if (reportId) {
              const report = this.reports.find(report => report.id === parseInt(reportId,10));
              if (report) {
                this.display_comment_modal(report);
              }
            }
            this.reports.forEach(report => {
              if (report && report.id) {
                this.getReportLikes(report);
              }
            });
          }
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    },

    togglePinReport(report) {
      const newPinStatus = !report.is_pinned;

      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/pin_report_api/`,
        method: 'POST',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          post_id: report.id,
          is_pinned: newPinStatus
        }),
        success: (response) => {
          if (response.status === 'success') {
            report.is_pinned = newPinStatus;

            const index = this.reports.indexOf(report);
            if (index > -1) {
              this.reports.splice(index, 1);
            }
            if (newPinStatus) {
              this.reports.unshift(report);
            } else {
              this.fetchReports();
            }
      }
        },
      });
    },

    display_comment_modal(report_data) {

      if (!this.handle_unauthenticated()) {
        return;
      }

      this.report_data = report_data;
      this.comment_modal_visible = true;
    },
    display_xHub_comment(exchange_hubs) {
      if(!this.handle_unauthenticated()){
        return;
      }
      this.exchange_hubs = exchange_hubs;
      this.xHub_comment_modal_visible = true;
    },

    hide_comment_comment() {
      this.report_data = {};
      this.comment_modal_visible = false;
    },
    hide_xhub_comment_comment() {
      this.exchange_hubs = {};
      this.xHub_comment_modal_visible = false;
    },
    handle_unauthenticated() {
      if (!this.token_exists) {
        window.location.href = escape("/sign_in_Finale");;
        return false;
      }
      return true;
    },
   
 
    truncateDescription(description, maxLength = 25) {
      const words = description.split(/\s+/);
      if (words.length > maxLength) {
        return words.slice(0, maxLength).join(" ") + '...';
      }
      return description;
    },
  
    getDisplayedReportDescription(report) {
      const description = report.description;

      if (this.isReportExpanded(report.id)) {
        return description;
      }
  
      return this.truncateDescription(description);
    },
  
    toggleReportReadMore(reportId) {
      const index = this.expandedReports.indexOf(reportId);
      if (index === -1) {
        this.expandedReports.push(reportId);
      } else {
        this.expandedReports.splice(index, 1);
      }
    },
  
    isReportExpanded(reportId) {
      return this.expandedReports.includes(reportId);
    },
  
    shouldShowReadMore(report) {
      const description = report.description;
      const wordCount = description.split(/\s+/).length;

      return wordCount > 25;
    },

    truncateHubDescription(description, maxLength = 25) {
      const words = description.split(/\s+/);
      if (words.length > maxLength) {
        return words.slice(0, maxLength).join(" ") + '...';
      }
      return description;
    },
  
    getDisplayedHubDescription(exchange_hub) {
      const description = exchange_hub.description;
  
      if (this.isHubExpanded(exchange_hub.id)) {
        return description;
      }
  
      return this.truncateHubDescription(description);
    },
  
    toggleHubReadMore(hubId) {
      const index = this.expandedHub.indexOf(hubId);
      if (index === -1) {
        this.expandedHub.push(hubId);
      } else {
        this.expandedHub.splice(index, 1);
      }
    },
  
    isHubExpanded(hubId) {
      return this.expandedHub.includes(hubId);
    },

    showReadMore(exchange_hub) {
      const description = exchange_hub.description;
      const wordCount = description.split(/\s+/).length;

      return wordCount > 25;
    },

    likeReport(report) {
      if (!this.token_exists) {
        this.$router.push('/sign_in_finale');
        return;
      }
      const reportId = report.id;
      
      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_report_like/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: JSON.stringify({ report_id: reportId }),
        success: (response) => {
          if (response.status === "success") {

          const liked_report = this.reports.find(like => like.id === reportId);
          if (liked_report) {
            liked_report.like_count++;
          }
            const likeButton = document.getElementById(`like-button${reportId}`);
            const likedButton = document.getElementById(`liked-button${reportId}`);


            if (likeButton) {
              likeButton.classList.add('d-none');
            }

            if (likedButton) {

              likedButton.classList.remove('d-none');
            }
        }
      }
      });
    },

    like_report_auth(report_id,report) {

      if (!this.handle_unauthenticated()) {
        return;
      }
      this.likeReport(report)
      this.$router.push({ name: 'home',params: {report_id: report_id,report:report}});
      
    },
    unlikeReport(report) {
      if (!this.token_exists) {
        this.$router.push('/sign_in_finale');
        return;
      }
      const reportId = report.id;
      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_report_like/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: JSON.stringify({ report_id: reportId }),
        success: (response) => {
          if (response.status === "success") {
            const likeButton = document.getElementById(`like-button${reportId}`);
            const likedButton = document.getElementById(`liked-button${reportId}`);

          const unliked_report = this.reports.find(like => like.id === report.id);
          if (unliked_report) {
            unliked_report.like_count--;
          }

            if (likeButton) {
              likeButton.classList.remove('d-none');
            }

            if (likedButton) {
              likedButton.classList.add('d-none');
            }
          }
        }
      });
    },
    isLoggedIn() {
      return !!this.getToken();
    },
    getReportLikes(report) {
      if (!report || !report.id) {
        return;
      }
      $.ajax({
        type: "GET",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/retrieve_report_likes/`,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: { report_id: report.id },
        success: (response) => {
          if (response.status === "success") {
            const likes = response.data;
            report.like_count = likes.length;
            
            const userId = this.get_user_id();
            const isLiked = likes.some(like => like.user.id === parseInt(userId, 10));

            const likeButton = document.getElementById(`like-button${report.id}`);
            const likedButton = document.getElementById(`liked-button${report.id}`);

            if (likeButton) {
              likeButton.classList.toggle('d-none', isLiked);
            }

            if (likedButton) {
              likedButton.classList.toggle('d-none', !isLiked);
            }
          }
        },
      });
    },
    likeExchangeHub(exchangeHub) {
      if (!this.token_exists) {
        this.$router.push('/sign_in_finale');
        return;
      }
      const exchangeHubId = exchangeHub.id;

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_exchange_hub_like/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: JSON.stringify({ exchangehub_id: exchangeHubId }),
        success: (response) => {
          if (response.status === "success") {

            exchangeHub.like_count++;
            const reactButton = document.getElementById(`react-button${exchangeHubId}`);
            const reactedButton = document.getElementById(`reacted-button${exchangeHubId}`);

            if (reactButton) {
              reactButton.classList.add('d-none');
            }

            if (reactedButton) {
              reactedButton.classList.remove('d-none');
            }
          }
        }
      });
    },

    like_exchangehub_auth(exchangehub_id, exchangeHub) {
      if (!this.handle_unauthenticated()) {
        return;
      }

      this.likeExchangeHub(exchangeHub);

      this.$router.push({ name: 'home', params: { exchangehub_id: exchangehub_id, exchangeHub: exchangeHub } });
    },
    unlikeExchangeHub(exchangeHub) {
      if (!this.token_exists) {
        this.$router.push('/sign_in_finale');
        return;
      }
      const exchangeHubId = exchangeHub.id;

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_exchange_hub_like/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: JSON.stringify({ exchangehub_id: exchangeHubId }),
        success: (response) => {
          if (response.status === "success") {
            exchangeHub.like_count--;
            const reactButton = document.getElementById(`react-button${exchangeHubId}`);
            const reactedButton = document.getElementById(`reacted-button${exchangeHubId}`);

            if (reactButton) {
              reactButton.classList.remove('d-none');
            }

            if (reactedButton) {
              reactedButton.classList.add('d-none');
            }
          }
        }
      });
    },
    getExchangeHubLikes(exchangeHub) {
      if (!exchangeHub || !exchangeHub.id) {
        return;
      }

      $.ajax({
        type: "GET",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/retrieve_exchange_hub_likes/`,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: { exchangehub_id: exchangeHub.id },
        success: (response) => {
          if (response.status === "success") {
            const likes = response.exchange_hub_likes;
            exchangeHub.like_count = likes.length;
            const userId = this.get_user_id();
            const isLiked = likes.some(like => like.user.id === parseInt(userId, 10));

            const reactButton = document.getElementById(`react-button${exchangeHub.id}`);
            const reactedButton = document.getElementById(`reacted-button${exchangeHub.id}`);

            if (reactButton) {
              reactButton.classList.toggle('d-none', isLiked);
            }

            if (reactedButton) {
              reactedButton.classList.toggle('d-none', !isLiked);
            }
          }
        },
      });
    },
    deleteExchangeHub(exchangehub_id) {
      if (!this.token_exists) {
        this.$router.push('/sign_in_finale');
        return;
      }
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          $.ajax({
            type: "DELETE",
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/delete_exchange_hub_api/`,
            data: JSON.stringify({ exchange_hub_id: exchangehub_id }),
            headers: {
              'Authorization': `Token ${this.getToken()}`,
              'Content-Type': 'application/json'
            },
            success: (response) => {
              const status = response.status;
              if (status === "success") {
                Swal.fire({
                  icon: 'success',
                  title: 'Voice deleted',
                  showConfirmButton: false,
                  timer: 1000
                }).then(() => {
                  window.location.reload();
                });
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'Failed to delete the voice. Please try again.',
                });;
              }
            }
          });
        }
      });
    },

    copyReportLinkToClipboard(report) {
      const link = this.baseUrl(report, 'report');
      navigator.clipboard.writeText(link).then(() => {
        this.showToast('Link copied to clipboard!');
      })
    },
    showToast(message) {
    document.querySelector('#copyToast .toast-body').textContent = message;

    $('#copyToast').fadeIn(300).addClass('show');
    setTimeout(function() {
      $('#copyToast').fadeOut(300).removeClass('show');
    }, 3000); 
  },
  copyVoiceLinkToClipboard(exchange_hub) {
    const link = this.baseUrl(exchange_hub, 'exchange_hub');
      navigator.clipboard.writeText(link).then(() => {
        this.showTheToast('Link copied to clipboard!');
      })
    },
    showTheToast(message) {
    document.querySelector('#copyTheToast .toast-body').textContent = message;

    $('#copyTheToast').fadeIn(300).addClass('show');
    setTimeout(function() {
      $('#copyTheToast').fadeOut(300).removeClass('show');
    }, 3000);
  },
  }
}