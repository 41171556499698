<body class="bg-light d-flex justify-content-center align-items-center vh-100 text-center">
    <div class=" p-5">
      <div class="display-1 fw-bold text-primary" >
        Oops!
      </div>
      <h1 class="fw-bold">ERROR</h1>
      <p class="text-muted">
        The user you are looking for could not be found, <br>
        please try again
      </p>
      <a href="/" class="btn btn-primary btn-lg mt-3">Go to Homepage</a>
    </div>
  </body>