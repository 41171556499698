import { clear_token, get_token, get_user_details } from "@/router";
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js';

import NotificationDropdown from '@/components/Notifications/Notification.vue'
export default {

  components: {
    NotificationDropdown
  },
  computed: {
    isHomePage() {
      return this.$route.name === 'home'; // Assuming your home page route is named 'home'
    },
    firstNameInitial() {
      return this.userDetails.first_name.charAt(0);
    }

  },
  mounted() {
    this.token_exists = get_token();
    this.user_details = get_user_details();
    this.user_id = this.get_user_id();

  },

  data() {
    return ({
      token_exists: false,
      user_id:null,
      user_details: {
        "user_role":'',
        "first_name":'',
        "last_name":'',
      },
      showIcons: false,
    });
  },

  methods: {
    get_user_id() {
      const user_id = localStorage.getItem('user_id') || '';
      return user_id;
  },
    logout_user() {
      Swal.fire({
        title: 'Are you sure?',
        text: "Are you sure you want to logout?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, log out!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          clear_token();
          window.location.reload();
        }
      });
    },
    toggleIcons() {
      this.showIcons = !this.showIcons;
    },


    myLearningView() {

      let user_type;
      if (this.user_details.user_role === 'customer') {
          user_type = 'learner';
      } else {
          user_type = 'admin';
      }
      const domain = window.location.hostname;
      let programe_name;
  
      if (domain.includes('workstreams') || domain.includes('demo')) {
          programe_name = 'workstreams learning';
      } else if (domain.includes('919')) {
          programe_name = '919 learning';
      } else if (domain.includes('chamber')) {
          programe_name = 'ICT Chamber learning';
      } else if (domain.includes('community')) {
          programe_name = 'Funax Community learning';
      }

    const expTime = new Date(Date.now() + 1 * 60 * 1000);
    const payload = {
          user_type: user_type,
          email: this.user_details.email,
          first_name: this.user_details.first_name,
          last_name: this.user_details.last_name,
          program_name: programe_name,
          exp: Math.floor(expTime.getTime() / 1000),
          return_url:window.location.protocol + '//' + window.location.origin + '/my_learning',
      };
      const secret = process.env.VUE_APP_JWT_SECRET_KEY;
    const header = {
          alg: "HS256",
          typ: "JWT",
      };

      const base64UrlEncode = (obj) =>
        btoa(JSON.stringify(obj))
            .replace(/=/g, "") 
            .replace(/\+/g, "-")
            .replace(/\//g, "_");
    
    const encodeJWT = (payload, secret) => {
        const encodedHeader = base64UrlEncode(header);
        const encodedPayload = base64UrlEncode(payload);

        const signature = CryptoJS.HmacSHA256(`${encodedHeader}.${encodedPayload}`, secret)
            .toString(CryptoJS.enc.Base64)
            .replace(/=/g, "") 
            .replace(/\+/g, "-")
            .replace(/\//g, "_");
        
        return `${encodedHeader}.${encodedPayload}.${signature}`;
    };
    
  
      const token = encodeJWT(payload, secret);
  
      const orcaaAiUrl = `${process.env.VUE_APP_ORCAA_URL}/admin_operation/jwt_login/?token=${token}&return_url=${window.location.origin + '/my_learning'}`;
  
      // Redirect user
      window.location.href = orcaaAiUrl;
  },
  
    open_maintenance() {
      if (!this.token_exists) {
          this.$router.push('/sign_in_finale');
          return;
      }
      this.$router.push('/incident_reporting');
}

  }
};