
import HomeNavBar from "@/components/HomeNavBar/HomeNavBar.vue"
import { get_token, get_user_details } from "@/router";
import Swal from "sweetalert2";

const GOOGLE_MAP_API_KEY = process.env.VUE_APP_GOOGLE_MAP_API_KEY;

export default {
  components: {
    HomeNavBar,
  },
  mounted() {
    this.user_id = this.$route.query.user_id
    this.user_details = get_user_details();
    this.token_exists = get_token();
    this.fetch_races();
    this.get_profile();
    this.fetch_gender();
    this.fetch_title();
    this.fetch_province();
   

    if (this.user_details) {
      this.first_name = this.user_details.first_name || '';
      this.last_name = this.user_details.last_name || '';
      this.email =this.user_details.email || '';
      this.phone_number =this.user_details.phone_number || '';
      
    }
  },
  data() {
    return ({
      first_name: '',
      last_name: '',
      email: '',
      gender: '',
      date_of_birth: '',
      phone_number: '',
      races: [],
      race_id: '',
      genders: [],
      gender_id: '',
      titles: [],
      title_id: '',
      cv: null,
      cv_url: null,
      skills : [],
      fileType: '',
      isLocalFile: false,
      local_file_url:null,
      street_address: '',
      town: '',
      postal_code: '',
      provinces: [],
      province_id: '',
      province_name: '',
      latitude: null,
      longitude: null,
      user_details: {
        "user_role": '',

      },
      token_exists: true,
      isLoading: false,
      profile: {},
      errors: {},
      showPreview: false,
      file_name: '',
      user_id: '',
      
    });
  },
  methods: {
    toggleSidebar() {
      this.$refs.sidebar.$el.classList.toggle('close');
    },
    handleFileUpload(event) {
      const files = event.target.files;
      if (files && files.length > 0) {
        const file = files[0];
        if (this.validateFile(file)) {
          this.cv = file;
          this.file_name = file.name;
          this.fileType = file.type;
          this.local_file_url = URL.createObjectURL(file);
          this.isLocalFile = true;
          this.showPreview = true;
          this.errorMessage = '';  // Clear error message
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Invalid File Type',
            text: 'Only PDF and Word documents are allowed.',
          });
          this.resetFileUpload();
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'No File Selected',
          text: 'Please select a file to upload.',
        });
        this.resetFileUpload();
      }
    },
    validateFile(file) {
      const allowedTypes = ['application/pdf', 'application/msword'];

      if(allowedTypes.includes(file.type)) {
        return true;
      } else {
        return false;
      }
    },
    validationProfileForm() {
      this.errors = {};
      let valid = true;

      if (this.first_name === '') {
        this.errors.first_name = 'First name is required';
        valid = false;
      }

      if (this.last_name === '') {
        this.errors.last_name = 'Last name is required';
        valid = false;
      }

      if (this.date_of_birth === '') {
        this.errors.date_of_birth = 'Date of birth is required';
        valid = false;
      }
      if (this.race_id === '') {
        this.errors.race_id = 'Race is required';
        valid = false;
      }
      if (this.gender_id === '') {
        this.errors.gender_id = 'Gender is required';
        valid = false;
      }
      if (this.title_id === '') {
        this.errors.title_id = 'Title is required';
        valid = false;
      }
      if (this.street_address === '') {
        this.errors.street_address = 'Street address is required';
        valid = false;
      }
      if (this.town === '') {
        this.errors.town = 'Town is required';
        valid = false;
      }
      if (this.postal_code === '') {
        this.errors.postal_code = 'Postal code is required';
        valid = false;
      }
      if (this.province_id === '') {
        this.errors.province_id = 'Province is required';
        valid = false;
      }
      if (!this.cv) {
        this.errors.cv = 'CV is required';
        valid = false;
      }

      return valid;
    },
    resetFileUpload() {
      // Reset the file upload fields
      this.cv = null;
      this.local_file_url = null;
      this.fileType = '';
      this.file_name = '';
      this.isLocalFile = false;
      this.showPreview = false;
      this.errorMessage = '';  // Reset error message
    },
    getLocation() {
      if (navigator.geolocation) {
        Swal.fire({
          title: 'Fetching Location...',
          text: 'Please wait while we retrieve your location.',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            Swal.close();
            Swal.fire({
              title: 'Location Retrieved!',
              text: `Success`,
              icon: 'success',
              showConfirmButton: false,
              timer: 2000
            });
            this.locationError = '';

          },
        );
      } else {
        this.locationError = 'Geolocation is not supported by this browser.';
      }
    },

    loadGoogleMapFromProfile() {

        if (!isNaN(this.latitude) && !isNaN(this.longitude)) {
            if (typeof window.google === 'undefined' || !window.google.maps) {

              const script = document.createElement('script'); 
              script.id = 'googleMapsScript'; 
              script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&callback=initMap&v=weekly`;
              script.async = true; 
              script.defer = true; 
              document.head.appendChild(script);

                window.initMap = () => {
                      this.initializeMap();
              };
            }
        }
    },

    initializeMap() {
      const lat = parseFloat(this.latitude);
      const lng = parseFloat(this.longitude);
      const mapOptions = {
          center: { lat: lat, lng: lng },
          zoom: 8,
          mapTypeId: 'roadmap',
      };

      const mapElement = $('.map');
      if (mapElement.length) {
          const map = new window.google.maps.Map(mapElement[0], mapOptions);

          if (window.google.maps.marker && window.google.maps.marker.AdvancedMarkerElement) {
              new window.google.maps.marker.AdvancedMarkerElement({
                  position: { lat: lat, lng: lng },
                  map: map,
                  title: 'Advanced Marker',
              });
          } else {
              new window.google.maps.Marker({
                  position: { lat: lat, lng: lng },
                  map: map,
                  title: 'Standard Marker',
              });
          }
      }
      },

    saveProfile() {
      if(!this.validationProfileForm()) {
        return;
      }

      Swal.fire({
        title: 'Submitting...',
        text: 'Please wait while we create the profile.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      this.isLoading = true;

      const formData = new FormData();

      formData.append('first_name', this.first_name);
      formData.append('last_name', this.last_name);
      formData.append('date_of_birth', this.date_of_birth);
      formData.append('gender_id', this.gender_id);
      formData.append('race_id', this.race_id);
      formData.append('title_id', this.title_id);
      formData.append('phone_number', this.phone_number);
      formData.append('street_address', this.street_address);
      formData.append('town', this.town);
      formData.append('postal_code', this.postal_code);
      formData.append('province_id', this.province_id);
      formData.append('latitude', this.latitude);
      formData.append('longitude', this.longitude);

      if (this.cv) {
        if (this.cv instanceof File) {
          formData.append('file', this.cv);
        }
      }
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/create_user_profile/`,
        type: 'POST',
        headers: {
          Authorization: `Token ${get_token()}`,
        },
        data: formData,
        processData: false,
        contentType: false,
        success: (body) => {
          if (body.status === 'success') {

            this.fetch_races();
            this.fetch_gender();
            this.fetch_title();
            this.fetch_province();


            Swal.fire({
              title: 'Success',
              text: 'Profile Created Successfully',
              icon: 'success',
              timer: 1000,
              showConfirmButton: false,
            }).then(() => {
              window.location.reload();
            });
          }
        },
        complete: () => {
          this.isLoading = false;
        },
      });

    this.isLoading = true;


    $.ajax({
      url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/update_user_profile/`,
      type: 'POST',
      headers: {
        Authorization: `Token ${get_token()}`,
      },
      data: formData,
      processData: false,
      contentType: false,


      success: (data) => {
        if (data.status === 'success') {
          this.fetch_races();
          this.fetch_gender();
          this.fetch_title();
          this.fetch_province();


          Swal.fire({
            title: 'Success',
            text: 'Profile Updated Successfully',
            icon: 'success',
            timer: 1000,
            showConfirmButton: false,
          }).then(() => {
            window.location.reload();
          });
        }
      },
      complete: () => {
        this.isLoading = false;
      },
    })


    },

    get_profile() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/get_profile/`,
        type: 'GET',
        headers: {
          Authorization: `Token ${get_token()}`,
          'Content-Type': 'application/json',
        },
        data: {
          user_id: this.user_id
        },
        success: (data) => {

          if (data.status === 'success' && data.data.profile_data.user) {
            const profile = data.data.profile_data;
            this.first_name = profile.user.first_name || '';
            this.last_name = profile.user.last_name || '';
            this.email = profile.user.email || '';
            this.date_of_birth = profile.date_of_birth || '';
            this.phone_number = profile.user.phone_number || '';
            this.street_address = profile.street_address || '';
            this.town = (profile.town || '').charAt(0).toUpperCase() + (profile.town || '').slice(1).toLowerCase();
            this.postal_code = profile.postal_code || '';
            this.province_id = profile.province_id || '';

            this.latitude = profile.latitude || '';
            this.longitude = profile.longitude || '';

            if (profile.user_skills && profile.user_skills.length > 0) {
              this.skills = profile.user_skills.map(item => item.skill.name);  // This ensures `skills` is an array
            }


            if (profile.profile_files && profile.profile_files.length > 0) {
              this.cv_url = profile.profile_files[0].file.file_url;
              this.presigned_url = profile.profile_files[0].file.presigned_url;
              this.cv = profile.profile_files[0].file;
              this.file_name = profile.profile_files[0].file.name;

            } else {
              this.cv = [];
            }
            for (const race of this.races) {
              if (race.race === data.data.profile_data.race) {
                this.race_id = race.id;
                break;
              }
            }

            for (const gender of this.genders) {
              if (gender.gender === data.data.profile_data.gender) {
                this.gender_id = gender.id;
                break;
              }
            }

            for (const province of this.provinces) {
              if (province.province_name === data.data.profile_data.province) {
                this.province_id = province.id;
                this.province_name = province.province_name;
                break;
              }
            }

            for (const title of this.titles) {
              if (title.title === data.data.profile_data.title) {
                this.title_id = title.id;
                break;
              }
            }

          }
          this.loadGoogleMapFromProfile();
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    },
    previewDocument() {
      if(this.cv_url) {
        this.showPreview = true;
        this.isLocalFile = false;
      }
    },
    closeModal() {
      this.showPreview = false;
      URL.revokeObjectURL(this.cvUrl); 
      this.cv_url = '';
    },
    fetch_races() {
      this.isLoading = true;

      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/get_races/`,
        type: 'GET',
        headers: {
          Authorization: `Token ${get_token()}`,
          'Content-Type': 'application/json',
        },
        success: (response) => {
          if (response.status === 'success') {
            this.races = response.data.race_data;

          }
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    },

    fetch_gender() {
      this.isLoading = true;

      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/get_genders/`,
        type: 'GET',
        headers: {
          Authorization: `Token ${get_token()}`,
          'Content-Type': 'application/json',
        },
        success: (response) => {
          if (response.status === 'success') {
            this.genders = response.data.gender_data;

            

          }
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    },

    fetch_title() {
      this.isLoading = true;

      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/get_titles/`,
        type: 'GET',
        headers: {
          Authorization: `Token ${get_token()}`,
          'Content-Type': 'application/json',
        },
        success: (response) => {
          if (response.status === 'success') {
            this.titles = response.data.title_data;

          }
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    },

    fetch_province() {
      this.isLoading = true;

      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/get_provinces/`,
        type: 'GET',
        headers: {
          Authorization: `Token ${get_token()}`,
          'Content-Type': 'application/json',
        },
        success: (response) => {
          if (response.status === 'success') {
            this.provinces = response.data.province_data;

          }
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    }
  },
};