import HomeNavBar from "@/components/HomeNavBar/HomeNavBar.vue"
import AllGroups from "@/components/GetGroups/GroupsComponent.vue"
import GroupFeedCommentModal from "@/components/GroupFeedCommentModal/GroupFeedComment.vue"
import GroupVoiceCommentModal from "@/components/GroupVoiceCommentModal/GroupVoiceComment.vue";
import FloatingButton from "@/components/FloatingButton/FloatingButtonComponent.vue";
import StickyNav from "@/components/BottomNavbar/StickyNavbar.vue"
import Swal from 'sweetalert2';
import { get_token,get_user_details } from "@/router";


export default {
  components: {
    HomeNavBar,
    AllGroups,
    StickyNav,
    GroupFeedCommentModal,
    GroupVoiceCommentModal,
    FloatingButton,
    group_creator_id: null,
    isCreator: false,
  },
  mounted() {
    this.token_exists = get_token();
    this.user_id = this.get_user_id();
    this.getGroupFeedLikes();
    this.fetchGroupMembers()
    this.fetchGroupVoices();
    this.fetchGroupFeeds();
    this.getGroupVoiceLikes();
  },
  watch: {
    '$route.params.group_id'() {
      this.fetchGroupMembers();
    }
  },
  data() {
    return ({
      group_members: {},
      token_exists: false,
      user_id: null,
      selectedMembers: [],
      user_details:get_user_details(),
      group_creator_id: null,
      isGroupCreator: false,
      isCreator: false,
      promptExitGroup: [],
      isExitIconVisible: false,
      comment_modal_visible: false,
      voice_comment_modal_visible: false,
      deleteMode: false,
      exitMode: false,
      group_voices:[],
      group_feeds:[],
      feed_data: {},
      voice_data: {},
      expandedVoices: [],
      expandedFeeds: [],
      GroupVoiceLikes: {},
    });
  },
  methods: {
    handle_unauthenticated() {
      if (!this.token_exists) {
        this.$router.push({ name: 'sign_in_finale', },
        );
        return false;
      }
      return true;
    },
    getToken() {
      return get_token();
    },
    get_user_id() {
      const user_id = localStorage.getItem('user_id') || '';
      return user_id;
    },
    fetchGroupMembers() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_group_member/`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },

        data: { group_id: this.$route.params.group_id },
        success: (response) => {
          this.group_members = response.members;
          this.group_creator_id = response.created_by;
          this.user_id = this.get_user_id();
          this.isCreator = Number(this.user_id) === Number(this.group_creator_id);
          },
        error: () => {
          this.group_members = ''
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    },
    showDeleteMode() {
      this.deleteMode = true;
      this.exitMode = false;
    },

    display_comment_modal(feed_data) {

      if (!this.handle_unauthenticated()) {
        return;
      }

      this.feed_data = feed_data;
      this.comment_modal_visible = true;
    },

    hide_comment_comment() {
      this.feed_data = {};
      this.comment_modal_visible = false;
    },
    handleExitCheckboxChange(event) {
      this.isExitIconVisible = event.target.checked;
    },
    showExitMode() {
      this.deleteMode = true;
      this.exitMode = false;
      Swal.fire({
        title: 'Exit Group',
        text: "You are about to exit a group.Are you sure?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, exit'
      }).then((result) => {
        if (result.isConfirmed) {
          this.memberExitGroup();
        }
      });
    },

    fetchGroupVoices() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_group_voice/`, 
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: { group_id: this.$route.params.group_id},
        success: (response) => {
          
          var status = response.status;
          if (status === "success") {
            this.group_voices= response.voice_data;

            const voice_id = this.getIdFromURL('voice_id');
            if (voice_id) {
              const groupVoice = this.group_voices.find(voice => voice.id === parseInt(voice_id,10));
              if (groupVoice) {
                this.display_voice_comment(groupVoice);
              }
            }
            this.group_voices.forEach((voice) => {
              if(voice && voice.id){
                this.getGroupVoiceLikes(voice);
              }
              });
          }
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    },

    fetchGroupFeeds() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_group_feed/`, 
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: { group_id: this.$route.params.group_id},
        success: (response) => {
          var status = response.status;
          if (status === "success") {
            this.group_feeds= response.feeds;

            const feed_id = this.getIdFromURL('feed_id');
            if (feed_id) {
              const groupFeed = this.group_feeds.find(feed => feed.id === parseInt(feed_id,10));
              if (groupFeed) {
                this.display_comment_modal(groupFeed);
              }
            }

            this.group_feeds.forEach((feed) => {
            if(feed && feed.id){
              this.getGroupFeedLikes(feed);
            }

            });
          }
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    },

    deleteGroupVoice(voice_id) {
      if (!this.token_exists) {
        this.$router.push('/sign_in_finale');
        return;
      }
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Deleting...',
            text: 'Please wait while we process your request.',
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });

          $.ajax({
            type: "DELETE",
            url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/delete_group_voice/`,
            data: JSON.stringify({ voice_id: voice_id }),
            headers: {
              'Authorization': `Token ${this.getToken()}`,
              'Content-Type': 'application/json'
            },
            success: (response) => {
              const status = response.status;
              if (status === "success") {
                Swal.fire({
                  title: 'Deleted!',
                  text: 'The group voice has been deleted.',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1000
                }).then(() => {
                  window.location.reload();
                });
              }
            }
          });
        }
      });
    },


    memberExitGroup() {
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/member_exit_group/`,
        method: 'DELETE',
        headers: {
          Authorization: `Token ${this.getToken()}`,
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          group_id: this.$route.params.group_id,
        }),
        success: (response) => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.message,
            showConfirmButton: false,
            timer: 2000,
          }).then(() => {
            window.location.reload();
            this.$router.push(`/view_group/${this.$route.params.group_id}/${this.$route.params.group_name}`);
          });
        },
        error: (xhr) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: xhr.responseJSON?.message || 'Failed to exit group',
          });
        },
      });
    },
    removeSelectedMembers() {
      if (this.selectedMembers.length === 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: 'No members selected for removal.',
        });
        return;
      }

      Swal.fire({
        title: 'Remove Members',
        text: "You are about to remove a user from the group.Are you sure?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continue'
      }).then((result) => {
        if (result.isConfirmed) {
          $.ajax({
            url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/remove_group_member/`,
            method: 'DELETE',
            headers: {
              Authorization: `Token ${this.getToken()}`,
              'Content-Type': 'application/json',
            },
            data: JSON.stringify({
              user_id: this.selectedMembers,
              group_id: this.$route.params.group_id,
            }),
            success: (response) => {
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.message,
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                window.location.reload();
                this.$router.push(`/view_group/${this.$route.params.group_id}/${this.$route.params.group_name}`);
              });
            },
            error: (xhr) => {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: xhr.responseJSON?.message || 'Failed to remove members.',
              });
            },
          });
        }
      });
    },

    deleteGroupFeed(feed_id) {
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Deleting...',
            text: 'Please wait while we process your request.',
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });

          $.ajax({
            type: "DELETE",
            url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/delete_group_feed/`,
            data: JSON.stringify({ feed_id: feed_id }),
            headers: {
              'Authorization': `Token ${this.getToken()}`,
              'Content-Type': 'application/json'
            },
            success: (response) => {
              const status = response.status;
              if (status === "success") {
                Swal.fire({
                  title: 'Deleted!',
                  text: 'The group feed has been deleted.',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1000
                }).then(() => {
                  window.location.reload();
                });
              }
            }
          });
        }
      });
    },

    truncateDescription(description, maxLength = 25) {
      const words = description.split(/\s+/);
      if (words.length > maxLength) {
        return words.slice(0, maxLength).join(" ") + '...';
      }
      return description;
    },
  
    getDisplayedFeedDescription(feed) {
      const description = feed.description;

      if (this.isFeedExpanded(feed.id)) {
        return description;
      }
  
      return this.truncateDescription(description);
    },
    isFeedExpanded(feedId) {
      return this.expandedFeeds.includes(feedId);
    },

    shouldShowReadMore(post) {
      const description = post.description;
      const wordCount = description.split(/\s+/).length;

      return wordCount > 25;
    },
    toggleFeedReadMore(feedId) {
      const index = this.expandedFeeds.indexOf(feedId);
      if (index === -1) {
        this.expandedFeeds.push(feedId);
      } else {
        this.expandedFeeds.splice(index, 1);
      }
    },
    closeVoiceMenu( voice_id) {
      const checkbox = document.getElementById('menu-open-' + voice_id);
      if (checkbox) {
        checkbox.checked = false;
      }
    },

    closeFeedMenu( feed_id) {
      const checkbox = document.getElementById('menu-open-' + feed_id);
      if (checkbox) {
        checkbox.checked = false;
      }
    },
    baseUrl(post, type) {
      const baseUrl = `${window.location.origin}${this.$route.path}`;
      if (type === 'voice') {
        return `${baseUrl}?voice_id=${post.id}`;
      }else if(type==='feed'){
        return `${baseUrl}?feed_id=${post.id}`;
      }
      return baseUrl
    },
    getIdFromURL(param) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    },

    display_voice_comment(voice_data) {
      if(!this.handle_unauthenticated()){
        return;
      }
      this.voice_data = voice_data;
      this.voice_comment_modal_visible = true;
    },

    hide_voice_comment_comment() {
      this.voice_data = {};
      this.voice_comment_modal_visible = false;
    },
    getDisplayedVoiceDescription(voice) {
      const description = voice.description;

      if (this.isVoiceExpanded(voice.id)) {
        return description;
      }

      return this.truncateDescription(description);
    },
    isVoiceExpanded(voiceId) {
      return this.expandedVoices.includes(voiceId);
    },

    toggleVoiceReadMore(voiceId) {
      const index = this.expandedVoices.indexOf(voiceId);
      if (index === -1) {
        this.expandedVoices.push(voiceId);
      } else {
        this.expandedVoices.splice(index, 1);
      }
    },

    likeFeed(group_feed) {
      if (!this.token_exists) {
        this.$router.push('/sign_in_finale');
        return;
      }
      const groupFeedId = group_feed.id;

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/group_management/add_group_feed_like/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: JSON.stringify({ group_feed_id: groupFeedId }),
        success: (response) => {
          if (response.status === "success") {


          const liked_report = this.group_feeds.find(like => like.id === groupFeedId);
          if (liked_report) {
            liked_report.total_likes++;
          }
            const likeButton = document.getElementById(`like-button${groupFeedId}`);
            const likedButton = document.getElementById(`liked-button${groupFeedId}`);


            if (likeButton) {
              likeButton.classList.add('d-none');
            }

            if (likedButton) {

              likedButton.classList.remove('d-none');
            }
        }
      }
      });
    },

    like_report_auth(group_feed_id,group_feed) {

      if (!this.handle_unauthenticated()) {
        return;
      }
      this.likeFeed(group_feed)
      this.$router.push({ name: 'home',params: {group_feed_id: group_feed_id,group_feed:group_feed}});

    },
    unlikeFeed(group_feed) {
      if (!this.token_exists) {
        this.$router.push('/sign_in_finale');
        return;
      }
      const groupFeedId = group_feed.id;
      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/group_management/add_group_feed_like/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: JSON.stringify({ group_feed_id: groupFeedId }),
        success: (response) => {
          if (response.status === "success") {
            const likeButton = document.getElementById(`like-button${groupFeedId}`);
            const likedButton = document.getElementById(`liked-button${groupFeedId}`);

          const unliked_report = this.group_feeds.find(like => like.id === group_feed.id);
          if (unliked_report) {
            unliked_report.total_likes--;
          }

            if (likeButton) {
              likeButton.classList.remove('d-none');
            }

            if (likedButton) {
              likedButton.classList.add('d-none');
            }
          }
        }
      });
    },
    getGroupFeedLikes(group_feed) {
      if (!group_feed || !group_feed.id) {
        return;
      }
      $.ajax({
        type: "GET",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/group_management/retrieve_group_feed_likes/`,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: { group_feed_id: group_feed.id },
        success: (response) => {
          if (response.status === "success") {
            const likes = response.data;
            group_feed.like_count = likes.length;

            const userId = this.get_user_id();
            const isLiked = likes.some(like => like.user.id === parseInt(userId, 10));

            const likeButton = document.getElementById(`like-button${group_feed.id}`);
            const likedButton = document.getElementById(`liked-button${group_feed.id}`);

            if (likeButton) {
              likeButton.classList.toggle('d-none', isLiked);
            }

            if (likedButton) {
              likedButton.classList.toggle('d-none', !isLiked);
            }
          }
        },
      });
    },
    likeVoice(group_voice) {
      if (!this.token_exists) {
          this.$router.push('/sign_in_finale');
          return;
      }
      const groupVoiceId = group_voice.id;
  
      $.ajax({
          type: "POST",
          url: `${process.env.VUE_APP_BACKEND_API_URL}/group_management/add_group_voice_like/`,
          contentType: "application/json",
          headers: {
              'Authorization': `Token ${this.getToken()}`,
          },
          data: JSON.stringify({ group_voice_id: groupVoiceId }),
          success: (response) => {
              if (response.status === "success") {
                  const liked_voice = this.group_voices.find(like => like.id === groupVoiceId);
                  if (liked_voice) {
                    liked_voice.total_likes++;
                  }
                  const reactButton = document.getElementById(`react-button${groupVoiceId}`);
                  const reactedButton = document.getElementById(`reacted-button${groupVoiceId}`);
  
                  if (reactButton) {
                    reactButton.classList.add('d-none');
                  }
                  if (reactedButton) {
                    reactedButton.classList.remove('d-none');
                  }
              }
          },
      });
  },
  
    like_voice_auth(group_voice_id,group_voice) {

      if (!this.handle_unauthenticated()) {
        return;
      }
      this.likeVoice(group_voice)
      this.$router.push({ name: 'home',params: {group_voice_id: group_voice_id,group_voice:group_voice}});

    },
    unlikeVoice(group_voice) {
      if (!this.token_exists) {
        this.$router.push('/sign_in_finale');
        return;
      }
      const groupVoiceId = group_voice.id;
      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/group_management/add_group_voice_like/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: JSON.stringify({ group_voice_id: groupVoiceId }),
        success: (response) => {
          if (response.status === "success") {
            const reactButton = document.getElementById(`react-button${groupVoiceId}`);
            const reactedButton = document.getElementById(`reacted-button${groupVoiceId}`);

          const unliked_voice = this.group_voices.find(like => like.id === group_voice.id);
          if (unliked_voice) {
            unliked_voice.total_likes--;
          }

            if (reactButton) {
              reactButton.classList.remove('d-none');
            }

            if (reactedButton) {
              reactedButton.classList.add('d-none');
            }
          }
        }
      });
    },
    getGroupVoiceLikes(group_voice) {
      if (!group_voice || !group_voice.id) {
        return;
      }
      $.ajax({
        type: "GET",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/group_management/retrieve_group_voice_likes/`,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: { group_voice_id: group_voice.id },
        success: (response) => {
          if (response.status === "success") {
            const likes = response.data;
            group_voice.total_likes = likes.length;

            const userId = this.get_user_id();
            const isLiked = likes.some(like => like.user.id === parseInt(userId, 10));

            const reactButton = document.getElementById(`react-button${group_voice.id}`);
            const reactedButton = document.getElementById(`reacted-button${group_voice.id}`);

            if (reactButton) {
              reactButton.classList.toggle('d-none', isLiked);
            }

            if (reactedButton) {
              reactedButton.classList.toggle('d-none', !isLiked);
            }
          }
        },
      });
    },

    copyFeedLinkToClipboard(feed) {
      const link = this.baseUrl(feed, 'feed');
      navigator.clipboard.writeText(link).then(() => {
        this.showToast('Link copied to clipboard!');
      })
    },
    showToast(message) {
    document.querySelector('#copyToast .toast-body').textContent = message;

    $('#copyToast').fadeIn(300).addClass('show');
    setTimeout(function() {
      $('#copyToast').fadeOut(30).removeClass('show');
    }, 3000); 
  },
  copyVoiceLinkToClipboard(voice) {
    const link = this.baseUrl(voice, 'voice');
      navigator.clipboard.writeText(link).then(() => {
        this.showTheToast('Link copied to clipboard!');
      })
    },
    showTheToast(message) {
    document.querySelector('#copyTheToast .toast-body').textContent = message;

    $('#copyTheToast').fadeIn(300).addClass('show');
    setTimeout(function() {
      $('#copyTheToast').fadeOut(300).removeClass('show');
    }, 3000);
  },
  }
}
