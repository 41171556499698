<div class="container-fluid">
  <div class="row">
    <div class="card-header p-0">
      <ul class="nav nav-pills mb-3 mt-2" id="incident-tabs" role="tablist">
        <!-- Report Incident Tab -->
        <li class="nav-item text-xs me-3" role="presentation">
          <a
            class="nav-link active"
            id="report-incident-tab"
            href="/incident_reporting"
            role="tab"
            aria-controls="report-incident"
            aria-selected="true"
          >
            <span class="rounded-circle bg-yellow p-1 shadow-sm color-purple">
              <i class="bx bxs-report fs-6"></i>
            </span>
            <span class="mx-2">Report Incident</span>
          </a>
        </li>

        <!-- View Incidents Tab -->
        <li class="nav-item text-xs" role="presentation">
          <a
            class="nav-link"
            id="view-incident-tab"
            href="/view_incident_reports"
            role="tab"
            aria-controls="view-incident"
            aria-selected="false"
          >
            <span class="rounded-circle bg-yellow p-1 shadow-sm color-purple">
              <i class="bx bxs-message-dots fs-6"></i>
            </span>
            <span class="mx-2">View Incidents</span>
          </a>
        </li>
      </ul>
  </div>
      <!-- Section with text and button -->
      <div class="py-4">

          <div class="card shadow rounded-25 bg-card-primary rounded-5 border-0">

              <div class="card-body">
                  <div class="d-flex justify-content-between px-md-1">
                    <a class="fs-6 fw-500 d-sm-block d-lg-block text-primary px-3 py-2 text-decoration-none" href="/">
                      <i class='bx bx-home me-1'></i> Home
                  </a>

                      <div class="p-2 ">
                          <i class="bi bi-exclamation-triangle-fill text-danger"></i>
                          Click on a button below to lodge an emergency.
                          <i class="bi bi-exclamation-triangle-fill me-2 text-danger"></i>
                      </div>
                      <button class="btn btn-primary text-xxs shadow rounded-5 text-xs" data-bs-toggle="modal" data-bs-target="#incidentModal"
                      :disabled="!(user_details.user_role === 'admin' || user_details.user_role === 'client')"
                      v-if="token_exists">
                      Create Incident Button
                      </button>
                  </div>

              </div>
          </div>
        </div>

      <!-- Mock Cards Section -->
      <div class="row justify-content-center py-4 mx-0">
        <!-- Dynamic Cards -->
        <div v-for="report in reports" :key="report.id" class="col-md-4 mb-4">
          <div class="card shadow-sm text-center bg-light border-0">
            <div class="card-body"
            @click="openReport(report)"
            >
              <h5 class="card-title">
                <i class="bi bi-lightning text-danger"></i> {{ report.name }}
              </h5>
              <p class="card-text">{{ report.description }}</p>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>

<!-- Modal for Creating Incident -->
<div class="modal fade" id="incidentModal" tabindex="-1" aria-labelledby="incidentModalLabel" aria-hidden="true">
<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <!-- Modal Header -->
    <div class="modal-header">
      <h5 class="modal-title text-xs fw-500" id="incidentModalLabel">Create Incident Button</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <!-- Modal Form -->
    <form>
      <div class="modal-body">
        <!-- Button Name Field -->
        <div class="mb-3">
          <label for="button_name" class="form-label text-xs fw-500">Button Name
            <span class="text-danger">*</span></label>
          <input
            type="text"
            id="button_name"
            class="form-control text-xs rounded-5"
            placeholder=""
            value=""
            v-model="name"
          />
          <span v-if="errors.name" class="text-danger text-xs">{{ errors.name }}</span>
        </div>

        <!-- Description Field -->
        <div class="mb-4">
          <label class="form-label text-xs fw-500">Incident Description <span class="text-danger">*</span></label>
          <QuillEditor
            theme="snow"
            class="quill-editor"
            placeholder="Enter description here..."
            toolbar="full"
            ref="quillSubmitEditorRef"
            v-model="description"
          />
        </div>
        <span v-if="errors.description" class="text-danger text-xs">{{ errors.description }}</span>

        <!-- Emoji Field -->
        <div class="mb-3">
          <label for="emoji" class="form-label text-xs fw-500">Emoji</label>
          <input
            type="text"
            id="emoji"
            class="form-control text-xs rounded-5"
            placeholder="🚦"
            value="🚦"
          />
        </div>

        <!-- Contact Persons Section -->
        <div class="mb-3">
          <i class="bi bi-envelope-fill"></i>
          <label class="form-label text-xs fw-500 d-block">Contact Persons
            <span class="text-danger">*</span>
          </label>

          <!-- Contact Input -->
          <div class="d-flex align-items-center">
            <input
              type="email"
              class="form-control text-xs rounded-5 me-3"
              placeholder="Enter email e.g. hotline@jra.org.za"
              value=""
              v-model="contact_email"
            />
          </div>
          <span v-if="errors.contact_email" class="text-danger text-xs ">{{ errors.contact_email }}</span>
          <div class="d-flex align-items-center mt-3">
            <input
            type="phone"
            class="form-control text-xs rounded-5 me-3"
            placeholder="Enter phone number. e.g. 0123456789"
            value=""
            v-model="contact_phone"
          />
        </div>

          <!-- Add Another Contact -->
          <a href="#" class="text-primary text-xs fw-500">+ Add Another Contact</a>
        </div>
      </div>

      <!-- Modal Footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cancel</button>
        <button type="submit" class="btn btn-primary btn-sm" @click.prevent="submitIncident()">Create</button>
      </div>
    </form>
  </div>
</div>
</div>




<div class="modal fade" id="incidentReportModal" tabindex="-1" aria-labelledby="incidentReportModalLabel" aria-hidden="true">
<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <!-- Modal Header -->
    <div class="modal-header">
      <h5 class="modal-title text-xs fw-500" id="incidentReportModalLabel">Create Incident Report for <span id="incidentReportCardType"></span></h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <!-- Modal Form -->
    <form @submit.prevent="createIncidentReport">
      <div class="modal-body">

        <div class="mb-4">
          <label class="form-label text-xs fw-500">Report Name <span class="text-danger">*</span></label>
          <input
            type="text"
            id="incidentReportName"
            class="form-control text-xs rounded-5"
            placeholder="Enter report name here..."
            v-model="report_title"
          />
          <p v-if="errors.incident_title" class="text-danger text-xs">{{ errors.incident_title}}</p>
        </div>
        <div class="mb-4">
          <label class="form-label text-xs fw-500">Incident Description <span class="text-danger">*</span></label>
          <QuillEditor
            theme="snow"
            ref="quillReportEditorRef" class="quill-editor"
            placeholder="Enter description here..."
            toolbar="full"
          />
        </div>
        <p v-if="errors.incident_description" class="text-danger text-xs">{{ errors.incident_description }}</p>
        
        <!-- Contact Persons Section -->
        <div class="file-upload my-2 me-4 flex-grow-1 d-flex flex-column align-items-start">
          <label for="fileInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
            <i class='bx bx-image-add file-upload-icon'></i> Add Report content
          </label>
          <input type="file" id="fileInput" class="file-input d-none" @change="handleFileUpload" />
          <div v-if="fileName" class="text-xs text-secondary fst-italic mt-1">
            <span class="fw-500">File name:</span> {{ fileName }}
          </div>
          <div v-if="fileError" class="text-xs text-danger fst-italic mt-1">{{ fileError }}</div>
        </div>

        <div class="mb-4">
          <label for="mapSearchBox" class="form-label text-xs fw-500 d-block">Search for Location</label>
          <input id="mapSearchBox" type="text" class="form-control map-search-box" placeholder="Search location..." v-model="location" />
          <p  v-if="errors.location" class="text-danger text-xs">{{ errors.location }}</p>
        </div>
        <div class="map"></div>
      </div>

      <!-- Modal Footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cancel</button>
        <button type="submit" class="btn btn-primary btn-sm">Create Report</button>
      </div>
    </form>
  </div>
</div>
</div>
