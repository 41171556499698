<!-- Navbar -->
<HomeNavBar/>
<!-- Main Content -->
<section>
  <div class="row content-section">

      <AllPortals @portal_selected="fetchPostsByPortal" />

    <!-- Middle Content -->
    <div class="col-lg-6 col-12 mt-4">
      <div class="background-section p-3">
        <h5 class="text-white mx-3">{{$route.params.portal_name}}</h5>
        <div class="card rounded-5 border-0 p-1 mt-4 m-3">     
          <div class="d-block d-md-none d-flex justify-content-center">
            <ul class="pills-billing-tab nav nav-pills mb-3 mt-2" id="pills-billing-tab" role="tablist">
              <li class="nav-item text-xs me-3" role="presentation">
                <button class="nav-link active" id="normal_loans_tab" data-bs-toggle="pill" data-bs-target="#normal_loans_info" type="button" role="tab" aria-controls="normal_loans_info" aria-selected="true">
                  <a class="rounded-circle bg-yellow p-1 shadow-sm color-purple"><i class='bx bxs-report fs-6'></i></a>
                  <span class="mx-2">Your Feed</span>
                </button>
              </li>
              <li class="nav-item text-xs" role="presentation">
                <button class="nav-link" id="top_loans_tab" data-bs-toggle="pill" data-bs-target="#top_loans_info" type="button" role="tab" aria-controls="top_loans_info" aria-selected="false">
                  <a class="rounded-circle bg-yellow p-1 shadow-sm color-purple"><i class='bx bxs-message-dots fs-6'></i></a>
                  <span class="mx-2">Your Voice</span>
                </button>
              </li>
            </ul>
          </div>
          

          <div class="d-none d-md-flex justify-content-center">
           
            <ul class="pills-billing-tab nav nav-pills mb-3" id="pills-billib-tab" role="tablist">
              <li class="nav-item text-sm me-3" role="presentation">
                <button class="nav-link active text-xs fw-500" id="normal_loans_tab" data-bs-toggle="pill" data-bs-target="#normal_loans_info" type="button" role="tab" aria-controls="normal_loans_info" aria-selected="true">
                  <a class="rounded-circle bg-yellow p-1 shadow-sm color-purple"><i class='bx bxs-report fs-5'></i></a>
                  <span class="mx-2">Your Feeds</span>
                </button>
              </li>
              <li class="nav-item text-sm mx-5" role="presentation">
                <button class="nav-link text-xs fw-500" id="top_loans_tab" data-bs-toggle="pill" data-bs-target="#top_loans_info" type="button" role="tab" aria-controls="top_loans_info" aria-selected="false">
                  <a class="rounded-circle bg-yellow p-1 shadow-sm color-purple"><i class='bx bxs-message-dots fs-5'></i></a>
                  <span class="mx-2">Your Voice</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
   
      <div class="post-section mt-3 overflow-auto p-3 h-700 position-relative">
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="normal_loans_info" role="tabpanel" aria-labelledby="normal_loans_tab">
            <div v-if="reports.length">
              <div v-for="report in reports" :key="report.id" class="card shadow card-hover bg-white border-0 mb-4 report-card p-3 rounded-5 d-flex flex-column">
                  <div class="row flex-grow-1">
                      <div class="col">
                          <div class="d-flex flex-start">
                              <i class='bx bxs-user-circle text-secondary fs-2'></i>
                              <div class="flex-grow-1 flex-shrink-1">
                                  <div>
                                      <div class="d-flex justify-content-between align-items-center">
                                          <p class="fw-500 mt-1 lh-1 text-xs">{{ report.creater.first_name }} {{ report.creater.last_name }} <span class="badge bg-warning text-dark mx-2 text-white shadow-sm"><i class='bx bxs-bulb'></i>{{report.topic_name}}</span><span v-if="report.is_pinned"><i class='bx bxs-pin'></i></span><br><span class="text-xxs"><i class="bx bxs-stopwatch"></i> {{ report.publication_date }}</span>
                                          </p>
                                          
                                          <div class="dropstart" v-if="String(report.creater.id) === String(user_id) && token_exists ||user_details.user_role=='admin'&& token_exists">
                                            <a href="" class="fw-bold text-dark" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                              <i class="bi bi-three-dots-vertical"></i><span class="small"></span>
                                            </a>
                                            <ul class="dropdown-menu custom-dropdown" aria-labelledby="dropdownMenuButton">
                  
                                              <li v-if="user_details.user_role=='admin'" class="dropdown-item text-xs border-bottom fw-bold" href="" @click.prevent="togglePinReport(report)">
                                                <i :class="report.is_pinned ? 'bx bx-pin fs-6' : 'bx bxs-pin fs-6'"></i>
                                                {{ report.is_pinned ? 'Unpin' : 'Pin' }}
                                              </li>
                                              <li>
                                                <a class="dropdown-item text-xxs fw-bold" href="" @click.prevent="deleteReport(report.id)"><i class='bx bxs-trash-alt fs-6'></i>Delete</a>
                                              </li>
                                            </ul>
                                          </div>

                                      </div>
                                  </div>
                              </div>
                          </div>
                          <p class="fw-500 text-xs">{{ report.name }}<a v-if="report.report_files" :href="report.report_link" class="text-xxs d-block mb-1">
                          <span class="text-dark fw-bold"></span> {{ report.report_link }}</a></p>
                          <div class="text-xs display-6">
                            <span v-html="getDisplayedReportDescription(report)" class="me-1"></span> <!-- 'me-1' adds a small right margin -->
                            <span 
                              v-if="shouldShowReadMore(report)" 
                              class="text-primary cursor-pointer text-xxs" 
                              @click="toggleReportReadMore(report.id)"
                            >
                              {{ isReportExpanded(report.id) ? 'Read Less' : 'Read More' }}
                              <i v-if="!isReportExpanded(report.id)" class='bx bx-down-arrow-circle'></i>
                              <i v-else class='bx bx-up-arrow-circle' ></i>
                            </span>
                          </div>
                          <div class="d-flex flex-column" v-if="report.report_files && report.report_files.length">
                            <div v-for="(fileObj, index) in report.report_files" :key="index" class="mb-3">
                              <!-- Image -->
                              <img v-if="fileObj.file.file_url.includes('.jpg') || 
                                fileObj.file.file_url.includes('.png') || 
                                fileObj.file.file_url.includes('.jpeg')"
                                   :src="fileObj.file.file_url"
                                   alt="Feed Image"
                                   class="img-fluid rounded-5 w-100 h-50" 
                                   @click="display_comment_modal(report)"/>
                              
                              <!-- Audio -->
                              <audio v-else-if="fileObj.file.file_url.includes('.mp3') || fileObj.file.file_url.includes('.wav')" 
                                     controls 
                                     class="w-100">
                                <source :src="fileObj.file.file_url" type="audio/mp3">
                                Your browser does not support the audio element.
                              </audio>
                              
                              <!-- Video -->
                              <video v-else 
                                     controls 
                                     class="img-fluid rounded-5 w-100">
                                <source :src="fileObj.file.file_url" type="video/mp4">
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          </div>
                        <div class="text-secondary mx-2 mt-1 text-end">
                            <span class="me-2 mt-2">{{ report.like_count || 0 }} <i class='bx bxs-like ' ></i></span>
                            <span>{{ report.total_comments }} <i class='bx bxs-comment'></i></span>
                        </div>
                          <div class="d-flex justify-content-between align-items-center border-top">
                            <div class="mb-3 ms-2 me-3 mt-1"> 
                              <span class="d-flex text-secondary " :id="'like-button' + report.id"  @click="likeReport(report)"><i  class="bi bi-hand-thumbs-up fw-bold"></i><span class="text-secondary text-xs mt-1 mx-1">Like</span></span>
                              <span :id="'liked-button' + report.id" class="d-none d-flex text-primary " @click="unlikeReport(report)"><i  class="bi bi-hand-thumbs-up-fill" ></i><span class="text-primary text-xs mt-1 mx-1">Unlike</span> </span>
                            </div>
                    
                            <div class="input-group mb-3 me-2 rounded-5 w-100 position-relative mt-1" @click="display_comment_modal(report)">
                              
                              <input type="text" class="form-control text-xs rounded-start rounded-end" placeholder="Write a comment .." readonly>
                            </div>
                            <div class="mb-3 me-2 menu mt-1" @click.stop>
                              <input type="checkbox" :id="'menu-open-' + report.id" class="menu-open" name="menu-open" />
                              <span><label :for="'menu-open-' + report.id" class="menu-open-button text-xs text-secondary d-flex" @click="boon"><i class="bi bi-share"></i><span class="text-secondary mx-1 ">Share</span></label></span>
                              
                              <a @click="copyReportLinkToClipboard(report)"  class="menu-item ">
                              <span @click="closeMenu(report.id)"><i class="bi bi-link fs-4 copy text-primary text-xxs"></i></span>
                              </a>

                              <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(baseUrl(report,'report'))" target="_blank" class="menu-item facebook-share-btn" @click="closeMenu(report.id)">
                                <i class="bi bi-facebook"></i>
                              </a>
                              <a :href="'https://twitter.com/intent/tweet?url=' + encodeURIComponent(baseUrl(report,'report'))" target="_blank" class="menu-item twitter-share-btn" @click="closeMenu(report.id)">
                                <i class="bi bi-twitter-x"></i>
                              </a>
                              <a :href="'mailto:?subject=Check%20this%20out&body=' + encodeURIComponent(baseUrl(report,'report'))" target="_blank" class="menu-item gmail-share-btn" @click="closeMenu(report.id)">
                                <i class="bi bi-google"></i>
                              </a>
                              <a :href="'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(baseUrl(report,'report'))" target="_blank" class="menu-item linkedin-share-btn" @click="closeMenu(report.id)">
                                <i class="bi bi-linkedin "></i>
                              </a>
                              <a :href="'https://api.whatsapp.com/send?text=' + encodeURIComponent(baseUrl(report,'report'))" target="_blank" class="menu-item whatsapp-share-btn" @click="closeMenu(report.id)">
                                <i class="bi bi-whatsapp "></i>
                              </a>
                              <a :href="'https://t.me/share/url?url=' + encodeURIComponent(baseUrl(report,'report'))" target="_blank" class="menu-item telegram-share-btn" @click="closeMenu(report.id)">
                                <i class="bi bi-telegram "></i>
                              </a>

                              <!-- toaster -->
                              <div aria-live="polite" aria-atomic="true" class="position-fixed custom-toast">
                                <div id="copyToast" class="toast align-items-center text-bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
                                  <div class="d-flex">
                                    <div class="toast-body">
                                      Link copied to clipboard!
                                    </div>
                                    <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>

                      </div>
                  </div>
              </div>
          </div>

            <div v-else class=" text-center">No available Feeds</div>
          </div>
 
          <!-- Exchange Hub Tab Content -->
          <div class="tab-pane fade" id="top_loans_info" role="tabpanel" aria-labelledby="top_loans_tab">
            <div v-if="exchange_hub_data.length">
              <div v-for="exchange_hub in exchange_hub_data" :key="exchange_hub.id" class="card card-hover bg-white border-0 mb-3 report-card p-2 shadow rounded-5">
                <div class="row flex-grow-1">
                  <div class="col">
                    <div class="d-flex flex-start">
                        <i class='bx bxs-user-circle text-secondary fs-2'></i>
                        <div class="flex-grow-1 flex-shrink-1">
                            <div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <p class="fw-500 mt-1 lh-1 text-xs">{{ exchange_hub.creater.first_name }} {{ exchange_hub.creater.last_name }} <span class="badge bg-warning text-dark mx-2 text-white shadow-sm"><i class='bx bxs-bulb'></i>{{exchange_hub.topic_name}}</span><br><span class="text-xxs"><i class="bx bxs-stopwatch"></i> {{ exchange_hub.publication_date }}</span>
                                    </p>

                                    <div class="dropstart" v-if="String(exchange_hub.creater.id) === String(user_id) && token_exists">
                                      <a href="" class="fw-bold text-dark" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="bi bi-three-dots-vertical"></i><span class="small"></span>
                                      </a>
                                      <ul class="dropdown-menu custom-dropdown" aria-labelledby="dropdownMenuButton">
                                        <li>
                                          <a class="dropdown-item text-xxs" href="" @click.prevent="deleteExchangeHub(exchange_hub.id)" >Delete</a>
                                        </li>
                                      </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="fw-500 text-xs">{{ exchange_hub.name }}<a v-if="exchange_hub.document" class="text-xxs d-block mb-1">
                    <span class="text-dark fw-bold"></span> {{ exchange_hub.report_link }}</a></p>
                    <div class="text-xs display-6">
                      <span v-html="getDisplayedHubDescription(exchange_hub)"></span>
                      <span 
                        v-if="showReadMore(exchange_hub)" 
                        class="text-primary cursor-pointer" 
                        @click="toggleHubReadMore(exchange_hub.id)"
                      >
                        {{ isHubExpanded(exchange_hub.id) ? 'Read Less' : 'Read More' }}
                      </span>
                    </div>
                    <div class="d-flex flex-column" v-if="exchange_hub.document && exchange_hub.document.length">
                      <div v-for="(fileObj, index) in exchange_hub.document" :key="index" class="mb-3">
                        <!-- Image -->
                        <img v-if="fileObj.file.file_url.includes('.jpg') || 
                          fileObj.file.file_url.includes('.png') || 
                          fileObj.file.file_url.includes('.jpeg')"
                             :src="fileObj.file.file_url"
                             alt="Feed Image"
                             class="img-fluid rounded-5 w-100 h-50" 
                             @click="display_comment_modal(report)"/>
                        
                        <!-- Audio -->
                        <audio v-else-if="fileObj.file.file_url.includes('.mp3') || fileObj.file.file_url.includes('.wav')" 
                               controls 
                               class="w-100">
                          <source :src="fileObj.file.file_url" type="audio/mp3">
                          Your browser does not support the audio element.
                        </audio>
                        
                        <!-- Video -->
                        <video v-else 
                               controls 
                               class="img-fluid rounded-5 w-100">
                          <source :src="fileObj.file.file_url" type="video/mp4">
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  <div class="text-secondary mx-2 mt-1 text-end">
                    <span class="me-2 mt-2">{{ exchange_hub.like_count || 0 }} <i class='bx bxs-like ' ></i></span>
                    <span>{{ exchange_hub.total_comments }} <i class='bx bxs-comment'></i></span>
                </div>
                    <div class="d-flex justify-content-between align-items-center border-top">
                      <div class="mb-3 ms-2 me-3 mt-1">
                        <span class="d-flex text-secondary " :id="'react-button' + exchange_hub.id"  @click="likeExchangeHub(exchange_hub)"><i  class="bi bi-hand-thumbs-up fw-bold"></i><span class="text-secondary text-xs mt-1 mx-1">Like</span></span>
                        <span :id="'reacted-button' + exchange_hub.id" class="d-none d-flex text-primary " @click="unlikeExchangeHub(exchange_hub)"><i  class="bi bi-hand-thumbs-up-fill" ></i><span class="text-primary text-xs mt-1 mx-1">Unlike</span> </span>
                      </div>

                      <div class="input-group mb-3 me-2 rounded-5 w-100 position-relative mt-1" @click="display_xHub_comment(exchange_hub)">

                        <input type="text" class="form-control text-xs rounded-start rounded-end" placeholder="Write a comment .." readonly>
                      </div>
                      <div class="mb-3 me-2 menu mt-1" @click.stop>
                        <input type="checkbox" :id="'menu-open-' + exchange_hub.id" class="menu-open" name="menu-open" />
                        <span><label :for="'menu-open-' + exchange_hub.id" class="menu-open-button text-xs text-secondary d-flex" @click="boon"><i class="bi bi-share"></i>
                          <span class="text-secondary mx-1 ">Share</span></label></span>
                          <a @click="copyVoiceLinkToClipboard(exchange_hub)"  class="menu-item ">
                            <span @click="closeFeedMenu(exchange_hub.id)"><i class="bi bi-link fs-4 copy text-primary text-xxs"></i></span>
                            </a>
                        <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(baseUrl(exchange_hub,'exchange_hub'))" target="_blank" class="menu-item facebook-share-btn" @click="closeFeedMenu(exchange_hub.id)">
                          <i class="bi bi-facebook"></i>
                        </a>
                        <a :href="'https://twitter.com/intent/tweet?url=' + encodeURIComponent(baseUrl(exchange_hub,'exchange_hub'))" target="_blank" class="menu-item twitter-share-btn" @click="closeFeedMenu(exchange_hub.id)">
                          <i class="bi bi-twitter-x"></i>
                        </a>
                        <a :href="'mailto:?subject=Check%20this%20out&body=' + encodeURIComponent(baseUrl(exchange_hub,'exchange_hub'))" target="_blank" class="menu-item gmail-share-btn" @click="closeFeedMenu(exchange_hub.id)">
                          <i class="bi bi-google"></i>
                        </a>
                        <a :href="'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(baseUrl(exchange_hub,'exchange_hub'))" target="_blank" class="menu-item linkedin-share-btn" @click="closeFeedMenu(exchange_hub.id)">
                          <i class="bi bi-linkedin"></i>
                        </a>
                        <a :href="'https://api.whatsapp.com/send?text=' + encodeURIComponent(baseUrl(exchange_hub,'exchange_hub'))" target="_blank" class="menu-item whatsapp-share-btn" @click="closeFeedMenu(exchange_hub.id)">
                          <i class="bi bi-whatsapp"></i>
                        </a>
                        <a :href="'https://t.me/share/url?url=' + encodeURIComponent(baseUrl(exchange_hub,'exchange_hub'))" target="_blank" class="menu-item telegram-share-btn" @click="closeFeedMenu(report.id)">
                          <i class="bi bi-telegram"></i>
                        </a>

                        <div aria-live="polite" aria-atomic="true" class="position-fixed custom-toast">
                          <div id="copyTheToast" class="toast align-items-center text-bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
                            <div class="d-flex">
                              <div class="toast-body">
                              </div>
                              <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                </div>

                </div>
              </div>
            </div>
            <div v-else class="text-center">No available voices</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Right Sidebar -->
    <div class="col-md-3 d-none">
      <div class="mx-5">
        <div class="reports">
          <h6>External Feeds</h6>
          <hr />
          <!-- Report 1 -->
          <div class="card card-hover bg-transparent border-0 mb-3 report-card" v-for="external_report in external_reports" :key="external_report.id">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex">
                  <img :src="external_report.external_report_files[0].file.file_url " alt="Coca Cola Report" class="rounded w-70px report-img"/>
                  <div class="ms-3 mt-3">
                    <h6 class="text-xs">{{external_report.external_report_name}}</h6>
                  </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
    <AllTopics @topic_selected="fetchPostsByTopic" :topics="topics"/>
  </div>
  <FloatingButton v-if="token_exists"/>
</section>

<StickyNav/>


<!-- Commenting modal -->
<ReportCommentModal v-if="comment_modal_visible"
  :report_data="report_data"
  @hide_comment_comment="hide_comment_comment"
/>

<ExchangeHubCommentModal v-if="xHub_comment_modal_visible"
  :exchange_hubs="exchange_hubs"
  @hide_xhub_comment_comment="hide_xhub_comment_comment"
/>

