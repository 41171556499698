<div>
  <HomeNavBar/>
  <div class="row d-flex justify-content-center align-items-center">
    <div class="col-lg-9 col-12">
              <!-- Bg -->
              <div class="pt-20 rounded-top bg-picture">
              </div>
              <div class="card rounded-bottom smooth-shadow-sm">
                <div class="d-flex align-items-center justify-content-between
                  pt-4 pb-6 px-4">
                  <!-- avatar -->
                  <div class="d-flex align-items-center">
                    <div class="avatar-xxl avatar-indicators avatar-online me-2
                      position-relative d-flex justify-content-end
                      align-items-end mt-n10">
                      <img src="@/assets/images/user.svg" class="avatar-xxl
                        rounded-circle border border-2 " alt="Image">
                    </div>
                    <!-- content -->
                    <div class="lh-2">
                      <h3 class="mb-0 text-secondary">{{first_name}} {{last_name}}
                        <a href="#!" class="text-decoration-none">
                        </a>
                      </h3>
                      <p class="mb-0 d-block  text-xs"><span class="text-xs text-secondary"><i class='bx bxs-envelope fs-6'></i></span>{{email}}</p>
                      <p class="mb-0 d-block text-xs"><span class="text-xs text-secondary"><i class='bx bxs-phone-call fs-6'></i></span> {{phone_number}}</p>
                      <p class="mb-0 d-block text-xs"><span class="text-xs text-secondary"><i class='bx bxs-map fs-6'></i></span> {{town}} {{province_name }}</p>
                    </div>

                  </div>
                  <div>
                    <!-- button -->
                    <a href="#" class="btn border-0 text-primary fs-4" data-bs-toggle="modal" data-bs-target="#profile_modal"><i class='bx bxs-edit-alt'></i></a>
                  </div>
                </div>
                <!-- nav -->
                
                <ul class="nav nav-lt-tab px-4 mb-3" id="pills-tab" role="tablist">
                  <button class="btn btn-primary text-xxs mt-3 mx-2 rounded-5 shadow" data-bs-toggle="modal" data-bs-target="#cv_modal"><i class="bi bi-eye"></i> View CV</button>
                </ul>
              </div>
            </div>

            <div class="col-lg-9 col-12 mt-3 "v-if="latitude && longitude">
              <div class="card rounded-5 shadow-sm border-0 mb-4">
                <div class="w-100 map"></div>
              </div>
            </div>

            <div class="col-lg-9 col-12 mt-3">
              <div class="card border-0 shadow-sm mb-4 rounded-5">
                  <!-- Skills Section -->
                  <div class="mt-3 ">
                    <h4 class="mb-0 text-secondary p-2">Skills</h4>
                      <div v-if="skills.length" class="d-flex flex-wrap gap-2 m-3">
                        <div
                          v-for="(skill, index) in skills"
                          :key="index"
                          class="badge bg-light text-dark py-2 px-2 rounded-pill shadow-sm  text-xs"
                        >
                          {{ skill }}
                        </div>
                      </div>
                  </div>
              </div>
          </div>
           
          </div>

          <div class="modal fade" id="profile_modal" tabindex="-1" aria-labelledby="profile_modallabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="profile_modallabel">Edit information</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="saveProfile" id="profileForm" novalidate class="text-xs">
                      <div class="row mb-4">
                        <div class="col-lg-6">
                          <label for="first_name" ref="first_name" class="form-label fw-500">First Name<span class="text-danger"> *</span></label>
                          <input type="text" class="form-control text-xs rounded-5" id="first_name" v-model="first_name"
                            placeholder="First name" :class="{ 'is-invalid': errors.first_name }">
                          <p v-if="errors.first_name" class="text-danger text-xs">{{ errors.first_name }}</p>
                        </div>
                        <div class="col-lg-6">
                          <label for="last_name" ref="last_name" class="form-label fw-500">Last Name<span class="text-danger"> *</span></label>
                          <input type="text" class="form-control text-xs rounded-5" id="last_name" v-model="last_name"
                            placeholder="Last name" :class="{ 'is-invalid': errors.last_name }">
                          <p v-if="errors.last_name" class="text-danger text-xs">{{ errors.last_name }}</p>
                        </div>
                      </div>
                      <div class="mb-3">
                        <label for="email" ref="email" class="form-label fw-500">Email<span class="text-danger"> *</span></label>
                        <input type="email" class="form-control text-xs rounded-5" id="email" readonly v-model="email"
                          placeholder="Email" :class="{ 'is-invalid': errors.email }">
                        <p v-if="errors.email" class="text-danger text-xs">{{ errors.email }}</p>
                      </div>
                      <div class="mb-3">
                          <label for="cv" ref="cv" class="form-label fw-500">CV<span class="text-danger"> *</span></label>
                          <div class="d-flex align-items-center">
                            <input
                              type="file"
                              class="form-control text-xs rounded-5"
                              id="cv"
                              @change="handleFileUpload"
                              accept=".pdf, .doc, .docx"
                            />
                          </div>
                          <p v-if="errors.cv" class="text-danger text-xs">{{ errors.cv }}</p>
                          <div v-if="file_name" class="text-danger text-sm mt-2">{{ file_name }}</div>
                        </div>
                
                        <div v-if="showPreview" class="modal-overlay" @click="closeModal">
                          <div class="modal-content" @click.stop>
                            <span class="close-button" @click="closeModal">&times;</span>
                            <div v-if="local_file_url" class="mt-4">
                              <iframe v-if="isLocalFile" :src="local_file_url" type="application/pdf"
                              frameborder="0"></iframe>
                            </div>

                            <div v-else>
                              <p>Preview not available for this file type.</p>
                            </div>
                          </div>
                        </div>
                 
                      <div class="mb-3">
                        <label for="date_of_birth" ref="date_of_birth" class="form-label fw-500">Date of Birth<span class="text-danger"> *</span></label>
                        <input type="date" class="form-control text-xs rounded-5" id="date_of_birth" v-model="date_of_birth"
                          placeholder="Date of birth" :class="{ 'is-invalid': errors.date_of_birth }" required>
                        <p v-if="errors.date_of_birth" class="text-danger text-xs">{{ errors.date_of_birth }}</p>
                      </div>
                      <div class="mb-3">
                        <label for="phone_number" ref="phone_number" class="form-label fw-500">Phone Number<span class="text-danger"> *</span></label>
                        <input type="text" class="form-control text-xs rounded-5" id="phone_number" v-model="phone_number"
                          placeholder="Phone number" :class="{ 'is-invalid': errors.phone_number }" required>
                        <p v-if="errors.phone_number" class="text-danger text-xs">{{ errors.phone_number }}</p>
                      </div>
                      
                      <div class="row mb-3">
                        <div class="col-lg-6">
                          <label for="race_id" ref="race_id" class="form-label fw-500">Race<span class="text-danger"> *</span></label>
                          <select class="form-control text-xs rounded-5" id="race_id" v-model="race_id"
                            :class="{ 'is-invalid': errors.race_id }">
                            <option class="text-muted" value="">Select Race</option>
                            <option v-for="race in races" :value="race.id" :key="race.id">
                              {{ race.race }}
                            </option>
                          </select>
                          <p v-if="errors.race_id" class="text-danger text-xs">{{ errors.race_id }}</p>
                        </div>
                        <div class="col-lg-6">
                          <label for="gender_id" ref="gender_id" class="form-label fw-500">Gender<span class="text-danger"> *</span></label>
                          <select class="form-control text-xs rounded-5" id="gender_id" v-model="gender_id"
                            :class="{ 'is-invalid': errors.gender_id }" required>
                            <option class="text-muted" value="">Select Gender</option>
                            <option v-for="gender in genders" :value="gender.id" :key="gender.id">{{ gender.gender }}</option>
                          </select>
                          <p v-if="errors.gender_id" class="text-danger text-xs">{{ errors.gender_id }}</p>
                        </div>
                      </div>
                      <div class="mb-3">
                        <label for="title" ref="title_id" class="form-label fw-500">Title<span class="text-danger"> *</span></label>
                        <select class="form-control text-xs rounded-5" id="title" v-model="title_id"
                          :class="{ 'is-invalid': errors.title_id }" required>
                          <option class="text-muted" value="">Select Title</option>
                          <option v-for="title in titles" :value="title.id" :key="title.id">{{ title.title }}</option>
                        </select>
                        <p v-if="errors.title_id" class="text-danger text-xs">{{ errors.title_id }}</p>
                      </div>
                      <div class="mb-3">
                        <label for="street_address" ref="street_address" class="form-label fw-500">Street Address<span class="text-danger"> *</span></label>
                        <input type="text" class="form-control text-xs rounded-5" id="street_address" v-model="street_address"
                          placeholder="Street address" :class="{ 'is-invalid': errors.street_address }" required>
                        <p v-if="errors.street_address" class="text-danger text-xs">{{ errors.street_address }}</p>
                      </div>
                      <div class="row mb-3">
                        <div class="col-lg-6">
                          <label for="town" ref="town" class="form-label fw-500">Town<span class="text-danger"> *</span></label>
                          <input type="text" class="form-control text-xs rounded-5" id="town" v-model="town" placeholder="Town"
                            :class="{ 'is-invalid': errors.town }" required>
                          <p v-if="errors.town" class="text-danger text-xs">{{ errors.town }}</p>
                        </div>
                        <div class="col-lg-6">
                          <label for="postal_code" ref="postal_code" class="form-label fw-500">Postal Code<span class="text-danger"> *</span></label>
                          <input type="text" class="form-control text-xs rounded-5" id="postal_code" v-model="postal_code"
                            placeholder="Postal code" :class="{ 'is-invalid': errors.postal_code }" required>
                          <p v-if="errors.postal_code" class="text-danger text-xs">{{ errors.postal_code }}</p>
                        </div>
                      </div>
                      <div class="mb-3">
                        <button type="button" @click="getLocation" class="btn btn-primary text-white text-xs shadow-sm rounded-5">Get Digital Location</button>
                        <p v-if="locationError" class="text-danger text-xs">{{ locationError }}</p>
                      </div>
                      <div class="mb-3">
                        <div class="row">
                          <div class="col-lg-6">
                            <label for="latitude" ref="latitude" class="form-label fw-500">Latitude<span class="text-danger"> *</span></label>
                            <input type="text" class="form-control text-xs rounded-5" id="latitude" v-model="latitude" placeholder="Latitude"
                              :class="{ 'is-invalid': errors.latitude }" required readonly>
                            <p v-if="errors.latitude" class="text-danger text-xs">{{ errors.latitude }}</p>
                          </div>
                          <div class="col-lg-6"> 
                            <label for="longitude" ref="longitude" class="form-label fw-500">Longitude<span class="text-danger"> *</span></label>
                            <input type="text" class="form-control text-xs rounded-5" id="longitude" v-model="longitude" placeholder="Longitude"
                              :class="{ 'is-invalid': errors.longitude }" required readonly>
                            <p v-if="errors.longitude" class="text-danger text-xs">{{ errors.longitude }}</p>
                          </div>
                        </div>
                      </div>
                
                      <div class="row mb-3">
                        <div class="col-md-12">
                          <label for="province_id" ref="province_id" class="form-label fw-500">Province<span class="text-danger"> *</span></label>
                          <select class="form-control text-xs rounded-5" id="province_id" v-model="province_id"
                            :class="{ 'is-invalid': errors.province_id }" required>
                            <option class="text-muted" value="">Select Province</option>
                            <option v-for="province in provinces" :value="province.id" :key="province.id">
                              {{ province.province_name }}
                            </option>
                          </select>
                          <p v-if="errors.province_id" class="text-danger text-xs">{{ errors.province_id }}</p>
                        </div>
                      </div>
                      <div class="text-end">
                        <button type="submit" class="btn btn-primary text-white text-xs shadow-sm rounded-5">Save</button>
                      </div>
                    </form>
                </div>
              </div>
            </div>
          </div>

          <!-- -CV MODAL -->
          <div class="modal fade" id="cv_modal" tabindex="-1" aria-labelledby="cv_modallabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="cv_modallabel">Modal title</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div v-if="cv_url" class="mt-4">
                    <iframe v-if="!isLocalFile"  :src="' https://docs.google.com/viewer?url='+ encodeURIComponent(cv_url) + '&embedded=true'" 
                    frameborder="0"></iframe>
                    </div>
                  <div v-else>
                    <p>Preview not available for this file type.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
  

</div>
