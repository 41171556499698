<nav class="navbar navbar-expand navbar-light bg-white topbar static-top shadow-sm mb-1">
  <!-- Sidebar Toggle (Topbar) -->
  <button
    id="sidebarToggleTop"
    class="btn text-light d-md-none rounded-circle mr-3 sidebar-burger sidebar-left border-0">
    <i class='bx bx-menu-alt-right'></i>
  </button>

  <!-- Navbar Brand (Logo) -->
  <a class="navbar-brand me-auto" href="#">
    <img :src="$clientLogo" class="img-fluid logo" alt="Logo"/>
  </a>
  

  <div :class="['dropdown text-center', {'me-3': token_exists}]">
   
    <router-link to="/"><a v-if="token_exists" class="text-secondary"><i class='bi bi-house-door-fill fs-3 me-3 mt-1' ></i></a></router-link>
    <i id="grid-icon" class="bi  bi-ui-checks-grid fs-4 " @click="toggleIcons"></i>

    <!-- Dropdown Menu -->
    <div v-if="showIcons" class="dropdown-menu dashboard-menu show p-3">
      <div class="row">
        <div class="col-4 text-center mb-2">
          <a class="dropdown-item text-xs rounded-ci" :href="user_details.user_role === 'customer' ? '/customer_profile' : '/profile'">
          <i class="bi bi-person-circle fs-4 "></i><br>
          <span class="icon-text text-xs fw-bold">My profile</span>
        </a>
        </div>
        
        <div class="col-4 text-center mb-2" v-if="token_exists && (user_details.user_role === 'admin' || user_details.user_role === 'client')">
          <a class="dropdown-item " href="/dashboard">
            <i class="bi bi-grid fs-4"></i><br>
            <span class="icon-text text-xs fw-bold">Dashboard</span>
          </a>
        </div>
        <div class="col-4 text-center mb-2 pointer-events-none">
          <a class="dropdown-item " @click="myLearningView">
          <img src="@/assets/images/orcaaai-icon.png" class="w-50" alt=""> <br>
          <span class="icon-text text-xs fw-bold">My Skill Passport</span>
        </a>
        </div>
        </div>
        <div class="row">
          <div class="col-4 text-center mb-2">
            <a class="dropdown-item " href="/my_community">
              <img src="@/assets/images/partners.png" class="w-50" alt=""> <br>
            <span class="icon-text text-xs fw-bold">My community</span>
          </a>
          </div>

          <div class="col-4 text-center mb-2">
            <a class="dropdown-item disabled">
              <img src="@/assets/images/taxi.png" class="w-50" alt=""> <br>
            <span class="icon-text text-xs fw-bold">My taxi</span>
          </a>
          </div>
          <div class="col-4 text-center mb-2">
            <a class="dropdown-item disabled">
              <img src="@/assets/images/stock.png" class="w-50" alt=""> <br>
            <span class="icon-text text-xs fw-bold">My market</span>
          </a>
          </div>
        </div>
        <div class="row mt-2">

          <div class="col-4 text-center mb-2">
            <a class="dropdown-item disabled">
              <img src="@/assets/images/home.png" class="w-50" alt=""> <br>
            <span class="icon-text text-xs fw-bold">My house</span>
          </a>
          </div>
          <div class="col-4 text-center mb-2">
            <a class="dropdown-item" @click.prevent="open_maintenance">
              <img src="@/assets/images/alert-icon-1551.png" class="w-50" alt=""> <br>
            <span class="icon-text text-xs fw-bold">Maintenance</span>
          </a>
          </div>
        </div>
      </div>
  </div>

  <NotificationDropdown />

  <!-- Navbar Links (Visible on all screen sizes) -->
  <ul v-if="token_exists" class="navbar-nav d-flex flex-row justify-content-center text-xs fw-500">
   

    <!-- Nav Item - Messages -->
    <li class="nav-item dropdown no-arrow mx-1">
      <a class="nav-link dropdown-toggle rounded-circle bg-yellow shadow color-purple fw-bold " href="#" id="messages_dropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      {{ user_details.first_name.charAt(0) }}
      </a>
      <div class="dropdown-menu dropdown-menu-end shadow animated--grow-in p-2 w-100 border-0 shadow-sm" aria-labelledby="messages_dropdown">
        <div class="text-xs shadow p-1  rounded-5">{{ user_details.first_name.charAt(0) }} {{user_details.last_name}}</div>
        <li>
          <a class="dropdown-item text-xs" :href="user_details.user_role === 'customer' ? '/customer_profile' : '/profile'">
            <i class="bi bi-person-square"></i> Profile
          </a>
        </li>
        <li><hr class="dropdown-divider"></li>
        <li>
          <button class="dropdown-item text-xs" @click="logout_user()">
            <i class="bx bx-log-out"></i> Log Out
          </button>
        </li>
      </div>
    </li>
  </ul>

  <ul :class="['navbar-nav d-flex flex-row text-xs fw-500', token_exists ? 'd-none' : '']">
    <li class="nav-item ms-2">
      <router-link class="nav-link btn btn-primary text-white text-xs shadow-sm rounded-5 p-2" to="sign_up_finale">
        Sign up
      </router-link>
    </li>
    <li class="nav-item mx-2">
      <a class="nav-link btn btn-primary text-white text-xs shadow-sm rounded-5 p-2" href="/sign_in_Finale">
        Login
      </a>
    </li>
  </ul>

    <!-- Navbar Links (Sign up / Login) -->
  
</nav>
